import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import Shheader from './Shheader';
import Shfooter from './Shfooter';
import { ClipLoader } from 'react-spinners'; // Import the spinner

function Shdashboard() {
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();
  const [schoolData, setSchoolData] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const storedUsername = sessionStorage.getItem("username");
    const hasRefreshed = sessionStorage.getItem("hasRefreshed");

    if (storedUsername) {
      if (!hasRefreshed) {
        setUsername(storedUsername);
       // fetchSchoolData(storedUsername);

        sessionStorage.setItem("hasRefreshed", "true");
        window.location.reload();
      } else {
        setUsername(storedUsername);
        fetchSchoolData(storedUsername);
      }
    } else {
      navigate("/schoollogin");
    }
    //}
  }, [navigate]);

  const fetchSchoolData = async (username) => {
    setLoading(true);
  await  axios
      .get(apiUrl+"/schoolinfo", { params: { username } })
      .then((res) => {
        setSchoolData(res.data);
        setLoading(false);
      })
      .catch((err) => {
        setLoading(false);
        console.error("Error fetching school data:", err);
      });
  };

  return (
    <>
      <Helmet>
        <title>CODEX | School | Dashboard</title>
        <meta name="description" content="School Dashboard Page." />
        <meta name="keywords" content="codex, school, react" />
      </Helmet>

      <Shheader />

      <main>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-title-container">
                <h1 className="mb-0 pb-0 display-4" id="title">
                  Dashboard
                </h1>
                <div>
                  {loading ? (
        <ClipLoader size={50} color={"#123abc"} loading={loading} /> // Show loading spinner
      ) : (
                <nav
                  className="breadcrumb-container d-inline-block"
                  aria-label="breadcrumb"
                >
                  <ul className="breadcrumb pt-0">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">Dashboards</a>
                    </li>
                  </ul>
             
                  {schoolData ? (
                    <h3 className="mb-0 pb-0 display-6" id="title">
                      Welcome {schoolData.schoolname}!
                    </h3>
                  ) : (
                    <h3 className="mb-0 pb-0 display-6" id="title">
                      {" "}
                      Loading school data...
                    </h3>
                  )}
               
                </nav>
              )}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12 col-lg-12">
              {/* Inventory Start */}
              <h2 className="small-title">Quick Statistics</h2>
              <div className="mb-5">
                <div className="row g-2">
                  <div className="col-12 col-sm-6 col-lg-3">
                    <div className="card hover-scale-up cursor-pointer sh-19">
                      <div className="h-100 d-flex flex-column justify-content-between card-body align-items-center">
                        <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                          <i data-acorn-icon="eye" className="text-white" />
                        </div>
                        <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                          {schoolData ? (
                            <p> {schoolData.teachers} </p>
                          ) : (
                            <p> Loading school data...</p>
                          )}
                        </div>
                        <div className="text text-primary">Teachers</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <div className="card hover-scale-up cursor-pointer sh-19">
                      <div className="h-100 d-flex flex-column justify-content-between card-body align-items-center">
                        <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                          <i data-acorn-icon="user" className="text-white" />
                        </div>
                        <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                          {schoolData ? (
                            <p> {schoolData.students} </p>
                          ) : (
                            <p> Loading school data...</p>
                          )}
                        </div>
                        <div className="text text-primary">Students</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <div className="card hover-scale-up cursor-pointer sh-19">
                      <div className="h-100 d-flex flex-column justify-content-between card-body align-items-center">
                        <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                          <i data-acorn-icon="office" className="text-white" />
                        </div>
                        <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                          {schoolData ? (
                            <p> {schoolData.seats} </p>
                          ) : (
                            <p> Loading school data...</p>
                          )}
                        </div>
                        <div className="text text-primary">Seats</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <div className="card hover-scale-up cursor-pointer sh-19">
                      <div className="h-100 d-flex flex-column justify-content-between card-body align-items-center">
                        <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                          <i
                            data-acorn-icon="calendar"
                            className="text-white"
                          />
                        </div>
                        <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                          {schoolData ? (
                            <p>
                              {" "}
                              {format(
                                new Date(schoolData.expiry),
                                "MMMM do, yyyy"
                              )}{" "}
                            </p>
                          ) : (
                            <p> Loading school data...</p>
                          )}
                        </div>
                        <div className="text text-primary">Next Renewal</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Inventory End */}
              {/* assignment Start */}
              {/* assignment End */}
            </div>
          </div>
          <div className="row">
            {/* Line Chart Start */}
            <div className="col-12 col-xl-6">
              <section className="scroll-section" id="lineChartTitle">
                <h2 className="small-title">Weekly Student Activity</h2>
                <div className="card mb-5">
                  <div className="card-body">
                    <div className="sh-35">
                      <canvas id="lineChart" />
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* Line Chart End */}
            {/* Area Chart Start */}
            <div className="col-12 col-xl-6">
              <section className="scroll-section" id="areaChartTitle">
                <h2 className="small-title">Weekly Teacher Activity</h2>
                <div className="card mb-5">
                  <div className="card-body">
                    <div className="sh-35">
                      <canvas id="areaChart" />
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* Area Chart End */}
          </div>
        </div>
      </main>

      <Shfooter />
      {/* </div> */}
    </>
  );
}

export default Shdashboard;
