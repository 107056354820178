import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Theader from "./Theader";
import Tfooter from "./Tfooter";
import { ClipLoader } from "react-spinners";
import CSVReader from "react-csv-reader";

function Tbulkupload() {
  const [data, setData] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;

  const handleFileLoad = (data) => {
    setData(data);
  };

  const handleUpload = async () => {
    try {
        // const res = await axios.get(apiUrl+"/divisions")  
      const response = await axios.post(apiUrl+"/studentbulkupload", { data });
      if (response.data.success) {
        alert("Data uploaded successfully");
      } else {
        alert("Failed to upload data");
      }
    } catch (error) {
      console.error("Error uploading data:", error);
      alert("An error occurred while uploading data");
    }
  };

  return (
    <>
      <Helmet>
        <title>CODEX | Teacher | Dashboard</title>
        <meta name="description" content="Teacher Dashboard Page." />
        <meta name="keywords" content="codex, school, react" />
      </Helmet>

      <Theader />

      <main>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-title-container">
                <h1 className="mb-0 pb-0 display-4" id="title">
                  Bulk Upload Students
                </h1>
                <div>
                  <nav
                    className="breadcrumb-container d-inline-block"
                    aria-label="breadcrumb"
                  >
                    <ul className="breadcrumb pt-0">
                      <li className="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#">Bulk Upload Students</a>
                      </li>
                    </ul>

                    {/* {teacherData ? (
                        <h3 className="mb-0 pb-0 display-6" id="title">
                          Welcome {teacherData.name}!
                        </h3>
                      ) : (
                        <h3 className="mb-0 pb-0 display-6" id="title">
                          {" "}
                          Loading school data...
                        </h3>
                      )} */}
                  </nav>
                </div>
              </div>
            </div>
          </div>

          <div>
          <div class="card mb-5">
              <div class="card-body">
                <p class="mb-0">Add Students to the LMS.</p>
              </div>
            </div>
           
            <div className="input-group">
            <CSVReader   type="file"  class="form-control" onFileLoaded={handleFileLoad}   aria-describedby="inputGroupFileAddon04"
                aria-label="Upload" />
            <button type="button" className="btn btn-outline-secondary" onClick={handleUpload}>Upload</button>
            </div>

          </div>

          <div>
          

           
            
          </div>
        </div>
      </main>

      <Tfooter />
      {/* </div> */}
    </>
  );
}

export default Tbulkupload;
