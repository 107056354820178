import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Theader from "./Theader";
import Tfooter from "./Tfooter";
import { format } from "date-fns";
import useScript from "../hooks/useScript";
import CryptoJS from 'crypto-js';
import { ClipLoader } from 'react-spinners'; // Import the spinner

function Tviewstudents() {

    const [username, setUsername] = useState("");
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [teacherData, setTeacherData] = useState(null);
    const [schoolData, setSchoolData] = useState(null);
    const [loading, setLoading] = useState(true); // Add loading state
    const [students, setStudents] = useState([]);
    const [values, setValues] = useState({
        schoolid: "",
        teacherid: "",
        userid: "",
        password: "",
        parentname: "",
        parentemail: "",
        parentpassword: "",
        studentname: "",
        studentemail: "",
        studentphone: "",
        studentlocation: "",
        grade: "",
        division: "",
      });

    useEffect(() => {
       
       
        const storedUsername = sessionStorage.getItem("tusername");
        const storedSchoolid = sessionStorage.getItem("schoolid");
        const storedTeacherid = sessionStorage.getItem("tuid");
        if (storedUsername) {
          fetchStudentsbyschool();
          setUsername(storedUsername);
        //   fetchSchoolData(storedUsername);
        fetchTeacherData(storedTeacherid);
          fetchSchoolData(storedSchoolid);
        } else {
          navigate("/teacherlogin");
        }
      }, []);


      const fetchSchoolData = async (id) => {
        //  setLoading(true);
          try {
              const res = await axios.get(apiUrl + "/schoolinfobyteacherid", { params: { id } });
             await setSchoolData(res.data);
            await setValues((prevValues) => ({
                ...prevValues,
                schoolid: res.data.id,
              }));
          } catch (err) {
              console.error("Error fetching school data:", err);
          } finally {
            //  setLoading(false);
          }
      };
   
      const fetchTeacherData = async (id) => {

        try {
            const res = await axios.get(apiUrl + "/teacherinfobyteacherid", { params: { id } });
            setTeacherData(res.data); 
        } catch (err) {
            console.error("Error fetching teacher data:", err);
        } };
    
      const fetchStudentsbyschool = async () => {

        try {
        setLoading(true);
        const uu = await sessionStorage.getItem("schoolid")

        const res = await axios.get(apiUrl+"/studentsbyschool", { params: { schoolid : uu } });       
          await setStudents(res.data);
            setLoading(false);
        }catch(err) {
            setLoading(false);
            console.error("Error fetching students data:", err);
          }
      };

  return (
    
    <>
    <Helmet>
      <title>CODEX | Teacher | View Students</title>
      <meta name="description" content="View Students" />
      <meta name="keywords" content="codex, school, react" />
      {/* <script src="js/plugins/datatable.editableboxed.js" async></script> */}
    </Helmet>

    <Theader />

    <main>
      <div className="container">
        <div className="row">
          <div className="col-12">
            <div className="page-title-container">
              {/* <h1 class="mb-0 pb-0 display-4" id="title">Teachers</h1> */}
              {loading ? (
        <ClipLoader size={50} color={"#123abc"} loading={loading} /> // Show loading spinner
      ) : (
              <nav
                className="breadcrumb-container d-inline-block"
                aria-label="breadcrumb"
              >
                {/* <ul class="breadcrumb pt-0">
            <li class="breadcrumb-item"><a href="#">Home</a></li>
            <li class="breadcrumb-item"><a href="#">Teachers</a></li>
          </ul> */}
                {teacherData ? (
                        <h3 className="mb-0 pb-0 display-6" id="title">
                          Welcome {teacherData.name}!
                        </h3>
                      ) : (
                        <h3 className="mb-0 pb-0 display-6" id="title">
                          {" "}
                          Loading school data...
                        </h3>
                      )}
              </nav>
 )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col">
            {/* Title and Top Buttons Start */}
            <div className="page-title-container">
              <div className="row">
                {/* Title Start */}
                <div className="col-12 col-md-7">
                  <h1 className="mb-0 pb-0 display-4" id="title">
                    Students
                  </h1>
                  <nav
                    className="breadcrumb-container d-inline-block"
                    aria-label="breadcrumb"
                  >
                    <ul className="breadcrumb pt-0">
                      <li className="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#">Students</a>
                      </li>
                    </ul>
                  </nav>
                </div>
                {/* Title End */}
                {/* Top Buttons Start */}
                <div className="col-12 col-md-5 d-flex align-items-start justify-content-end">
                  {/* Add New Button Start */}
                  <a
                    href="/teacheraddstudent"
                    className="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"
                  >
                    <i data-acorn-icon="plus" />
                    <span>Add New.</span>
                  </a>
                 
                </div>
                {/* Top Buttons End */}
              </div>
            </div>





<section className="scroll-section" id="stripe">
<div className="card mb-5">
  <div className="card-body">
    {/* Stripe Controls Start */}
    <div className="row">
      <div className="col-12 col-sm-5 col-lg-3 col-xxl-2 mb-1">
        <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 border border-separator bg-foreground search-sm">
          <input
            className="form-control form-control-sm datatable-search"
            placeholder="Search"
            data-datatable="#datatableStripe"
          />
          <span className="search-magnifier-icon">
            <i data-acorn-icon="search" />
          </span>
          <span className="search-delete-icon d-none">
            <i data-acorn-icon="close" />
          </span>
        </div>
      </div>
      <div className="col-12 col-sm-7 col-lg-9 col-xxl-10 text-end mb-1">
        <div className="d-inline-block">
          <button
            className="btn btn-icon btn-icon-only btn-outline-muted btn-sm datatable-print"
            type="button"
            data-datatable="#datatableStripe"
          >
            <i data-acorn-icon="print" />
          </button>
          <div
            className="d-inline-block datatable-export"
            data-datatable="#datatableStripe"
          >
            <button
              className="btn btn-icon btn-icon-only btn-outline-muted btn-sm dropdown"
              data-bs-toggle="dropdown"
              type="button"
              data-bs-offset="0,3"
            >
              <i data-acorn-icon="download" />
            </button>
            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
              <button className="dropdown-item export-copy" type="button">
                Copy
              </button>
              <button className="dropdown-item export-excel" type="button">
                Excel
              </button>
              <button className="dropdown-item export-cvs" type="button">
                Cvs
              </button>
            </div>
          </div>
          <div
            className="dropdown-as-select d-inline-block datatable-length"
            data-datatable="#datatableStripe"
          >
            <button
              className="btn btn-outline-muted btn-sm dropdown-toggle"
              type="button"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
              data-bs-offset="0,3"
            >
              10 Items
            </button>
            <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
              <a className="dropdown-item" href="#">
                5 Items
              </a>
              <a className="dropdown-item active" href="#">
                10 Items
              </a>
              <a className="dropdown-item" href="#">
                20 Items
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    {/* Stripe Controls End */}
    {/* Stripe Table Start */}
    <table
      className="data-table data-table-pagination data-table-standard responsive nowrap stripe"
      id="datatableStripe"
      data-order='[[ 0, "desc" ]]'
    >
       <thead><tr>
                      <th className="text-muted text-small text-uppercase">Name</th>
                      <th className="text-muted text-small text-uppercase">Grade</th>
                      <th className="text-muted text-small text-uppercase">Division</th>
                      <th className="text-muted text-small text-uppercase">User id</th>
                      <th className="text-muted text-small text-uppercase">Password</th>
                      <th className="text-muted text-small text-uppercase">Enrolled</th>
                      <th className="text-muted text-small text-uppercase">Status</th>
                      {/* <th className="text-muted text-small text-uppercase">Action</th> */}
                  </tr></thead>
                  <tbody>
                    {students.map((student, index) => (
                      <tr key={index}>
                        <td>{student.studentname}</td>
                        <td>{student.grade}</td>
                        <td>{student.division}</td>
                        <td>{student.userid}</td>
                        <td>{student.password}</td>
                        <td>{student.status === 1 ? "Active" : "Inactive"}</td>
                        {schoolData ? (<td> {format(new Date(student.created), 'MMMM do, yyyy')} </td>) : ( <p> Loading school data...</p> )}
                        {/* <td> <Link className='btn mx-2 btn-blue' to={`/schooleditteacher/${encryptId(teacher.id)}`}>Edit</Link></td>                           */}
                        {/* <td> <Link className='btn mx-2 btn-blue' to={`/schooleditteacher/4`}>Edit</Link></td>                           */}
                      </tr>
                    ))}
                  </tbody>

    </table>
    {/* Stripe Table End */}
  </div>
</div>
</section>















          </div>
        </div>
      </div>
    </main>

    <Tfooter />
  </>


  )
}

export default Tviewstudents