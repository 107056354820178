import React from 'react';
import { useNavigate } from "react-router-dom";

function Shquicksidemenu() {


  const navigate = useNavigate();

  const handleLogout = (e) => {
    e.preventDefault();
    // Clear user data from local storage or session storage
    localStorage.removeItem('userToken');
    sessionStorage.removeItem('username');
    sessionStorage.removeItem('uid');
    sessionStorage.removeItem('hasRefreshedsupport');
    sessionStorage.removeItem('hasRefreshed');
    
    // Redirect to login page
    navigate('/schoollogin');
  };

  return (
    <>
       <div className="nav flex-column" role="tablist">
                  <a
                    className="nav-link px-0 border-bottom border-separator-light"
                    href="/schoolprofile"
                    role="tab"
                  >
                    <i
                      data-acorn-icon="user"
                      className="me-2"
                      data-acorn-size={17}
                    />
                    <span className="align-middle">My Profile</span>
                  </a>
                  <a
                    className="nav-link px-0 border-bottom border-separator-light"
                    href="/schoolpreferences"
                    role="tab"
                  >
                    <i
                      data-acorn-icon="gear"
                      className="me-2"
                      data-acorn-size={17}
                    />
                    <span className="align-middle">My Preferences</span>
                  </a>


                  <div className="mb-3 row mt-5">
                  <div className="col-sm-8 col-md-9 col-lg-10 ">
                  <button  className="btn btn-primary" onClick={handleLogout}> Logout </button>
                    </div></div>
                  
                  {/* <a
                    className="nav-link px-0"
                    href="codex.school.login.html"
                    role="tab"
                  >
                    <i
                      data-acorn-icon="lock-off"
                      className="me-2"
                      data-acorn-size={17}
                    />
                    <span className="align-middle">Logout</span>
                  </a> */}
                </div>
    </>
  )
}

export default Shquicksidemenu