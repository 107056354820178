import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Shheader from "./Shheader";
import Shfooter from "./Shfooter";

function Shaddstudent() {

  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const [schoolData, setSchoolData] = useState(null);
  const [grades, setGrades] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [values, setValues] = useState({
    schoolid: "",
    userid: "",
    password: "",
    parentname: "",
    parentemail: "",
    parentpassword: "",
    studentname: "",
    studentemail: "",
    studentphone: "",
    studentlocation: "",
    grade: "",
    division: "",
  });

  useEffect(() => {
    fetchGrades();
    fetchDivisions();
    const storedUsername = sessionStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
      fetchSchoolData(storedUsername);
    } else {
      navigate("/schoollogin");
    }
  }, []);

  const fetchGrades = () => {
    axios
      .get("/grades")
      .then((res) => {
        setGrades(res.data);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  };

  const fetchDivisions = () => {
    axios
      .get(apiUrl+"/divisions")
      .then((res) => {
        setDivisions(res.data);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  };

  const fetchSchoolData = (username) => {
    axios
      .get(apiUrl+"/schoolinfo", { params: { username } })
      .then((res) => {
        setSchoolData(res.data);
        setValues((prevValues) => ({
          ...prevValues,
          schoolid: res.data.id,
        }));
      })
      .catch((err) => {
        console.error("Error fetching school data:", err);
      });
  };

  const handleChange = (e) => {
    const { name } = e.target;
    const selectedText = e.target.options[e.target.selectedIndex].text;
    setValues({
      ...values,
      [name]: selectedText,
    });
  };

  const updatesdata = async (schoolid) => {
    try {
      await axios.post(apiUrl+"/updatestudentscount",null, { params: { schoolid : schoolid} });
      // toast.success(`School ID: ${schoolid} updated successfully`);
      console.log('Update successful');
    } catch (error) {
      console.error('Update Error:', error);
      // toast.error(`Error now: ${error} `);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Submitting values:", values);
    axios
      .post(apiUrl+"/add_student_byschool", values)
      .then((res) => {
        if (res.data === "success") {
          updatesdata(values.schoolid);
          toast.success("Student Record added successfully");
          resetForm();
        } else {
          toast.error("Failed to add student record");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occurred. Please try again.");
      });
  };

  const resetForm = () => {
    setValues({
      schoolid: schoolData ? schoolData.id : "",
      userid: "",
      password: "",
      parentname: "", 
      parentemail: "", 
      parentpassword: "", 
      studentname: "", 
      studentemail: "", 
      studentphone: "", 
      studentlocation: "", 
      grade: "", 
      division: "", 
    });
  };

  return (
    <>
      <Helmet>
        <title>CODEX | School | Add Student</title>
        <meta name="description" content="Add Student" />
        <meta name="keywords" content="codex, school, react" />
      </Helmet>

      <Shheader />

      <main>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-title-container">
                {/* <h1 class="mb-0 pb-0 display-4" id="title">Teachers</h1> */}
                <nav
                  className="breadcrumb-container d-inline-block"
                  aria-label="breadcrumb"
                >
                  {/* <ul class="breadcrumb pt-0">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item"><a href="#">Teachers</a></li>
            </ul> */}
                  {schoolData ? (
                    <h3 className="mb-0 pb-0 display-6" id="title">
                      Welcome {schoolData.schoolname}!
                    </h3>
                  ) : (
                    <h3 className="mb-0 pb-0 display-6" id="title">
                      {"Loading school data..."}
                    </h3>
                  )}
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {/* Title Start */}
              <section className="scroll-section" id="title">
                <div className="page-title-container">
                  <h1 className="mb-0 pb-0 display-4">Add Student</h1>
                  <nav
                    className="breadcrumb-container d-inline-block"
                    aria-label="breadcrumb"
                  >
                    <ul className="breadcrumb pt-0">
                      <li className="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#">Add Student</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </section>
              {/* Title End */}
              {/* Content Start */}
              <div>
                <div className="card mb-5">
                  <div className="card-body">
                    <p className="mb-0">Add Students to the LMS.</p>
                  </div>
                </div>
                {/* Login Start */}
                <form
                    className="card mb-5 tooltip-end-top"
                    //   id="loginForm"
                    noValidate=""
                    onSubmit={handleSubmit}
                  >
                    <input
                      type="hidden"
                      name="schoolid"
                      value={values.schoolid}
                    />
                <section className="scroll-section" id="login">
                  <h2 className="small-title">Login credentials for student</h2>
              
                    <div className="card-body">
                      <p className="text-alternate mb-4">
                        Please use your credentials to login.
                      </p>
                      <div className="mb-3 filled">
                        <i data-acorn-icon="user" />
                        <input
                          className="form-control"
                          placeholder="User ID"
                          name="userid"
                          value={values.userid}
                          onChange={(e) =>
                            setValues({ ...values, userid: e.target.value })
                          }
                          required
                        />
                      </div>
                      <div className="mb-3 filled">
                        <i data-acorn-icon="lock-off" />
                        <input
                          className="form-control"
                          type="password"
                          name="password"
                          placeholder="Password"
                          value={values.password}
                          onChange={(e) =>
                            setValues({ ...values, password: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
                 
                </section>
                <section className="scroll-section" id="loginp">
                  <h2 className="small-title">Login credentials for parent.</h2>
                    <div className="card-body">
                      <p className="text-alternate mb-4">
                        Please use your credentials to login.
                      </p>
                      <div className="mb-3 filled">
                        <i data-acorn-icon="user" />
                        <input
                          className="form-control"
                          placeholder="Full name of Parent"
                          name="parentname"
                          value={values.parentname}
                          onChange={(e) =>
                            setValues({ ...values, parentname: e.target.value })
                          }
                          required
                        />
                      </div>
                      <div className="mb-3 filled">
                        <i data-acorn-icon="email" />
                        <input
                          className="form-control"
                          placeholder="Email"
                          name="parentemail"
                          value={values.parentemail}
                          onChange={(e) =>
                            setValues({ ...values, parentemail: e.target.value })
                          }
                          required
                        />
                      </div>
                      <div className="mb-3 filled">
                        <i data-acorn-icon="lock-off" />
                        <input
                          className="form-control"
                          type="password"
                          name="parentpassword"
                          placeholder="Password"
                          value={values.parentpassword}
                          onChange={(e) =>
                            setValues({ ...values, parentpassword: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
                
                </section>
                {/* Login End */}
                {/* Sign Up Start */}
                <section className="scroll-section" id="info">
                  <h2 className="small-title">Personal info</h2>
                    <div className="card-body">
                      <p className="text-alternate mb-4">
                        Details about the student.
                      </p>
                      <div className="mb-3 filled">
                        <i data-acorn-icon="user" />
                        <input
                          className="form-control"
                          placeholder="Name"
                          name="studentname"
                          value={values.studentname}
                          onChange={(e) =>
                            setValues({ ...values, studentname: e.target.value })
                          }
                          required
                        />
                      </div>
                      <div className="mb-3 filled">
                        <i data-acorn-icon="email" />
                        <input
                          className="form-control"
                          placeholder="Email"
                          name="studentemail"
                          value={values.studentemail}
                          onChange={(e) =>
                            setValues({ ...values, studentemail: e.target.value })
                          }
                          required
                        />
                      </div>
                      <div className="mb-3 filled">
                        <i data-acorn-icon="phone" />
                        <input
                          className="form-control"
                          type="phone"
                          placeholder="Phone"
                          name="studentphone"
                          value={values.studentphone}
                          onChange={(e) =>
                            setValues({ ...values, studentphone: e.target.value })
                          }
                          required
                        />
                      </div>
                      <div className="mb-3 filled">
                        <i data-acorn-icon="pin" />
                        <input
                          className="form-control"
                          type="Location"
                          placeholder="Location"
                          name="studentlocation"
                          value={values.studentlocation}
                          onChange={(e) =>
                            setValues({ ...values, studentlocation: e.target.value })
                          }
                          required
                        />
                      </div>
                    </div>
             
                </section>
                {/* Sign Up End */}
                {/* Personal Start */}
                <section className="scroll-section" id="assigned">
                  <h2 className="small-title">Class info</h2>
                    <div className="card mb-5">
                      <div className="card-body">
                        <p className="text-alternate mb-4">
                          Mention which grade and division is assigned to the
                          teacher!
                        </p>
                        <div className="row g-3">
                          <div className="col-md-6">
                          <label className="form-label mb-3 w-100">Grades</label>
                            <select
                              // id="select2Basic"
                              name="grade"
                              // value={values.grade}
                              onChange={handleChange}
                              // onChange={(e) => handleChange(e.target.value)}
                              // onChange={(e) => setValues({ ...values, grade: e.target.value })}
                              // onChange={(e)=>this.handleChange(e)}
                              // onChange={(e) => handleChange({ ...values, grade: e.target.value })}
                              className="w-100"
                            >
                              <option value="">Select a grade</option>
                              {grades.map((grad) => (
                                <option key={grad.id} value={grad.id}>
                                  {grad.gradename}
                                </option>
                              ))}
                            </select>
                          </div>
                          <div className="col-md-6">
                          <label className="form-label mb-3 w-100">
                              Divisions
                            </label>
                            <select
                              // id="selectTemplating"
                              name="division"
                              // value={values.division}
                              onChange={handleChange}
                              className="w-100"
                            >
                              <option value="">Select a division</option>
                              {divisions.map((divi) => (
                                <option key={divi.id} value={divi.id}>
                                  {divi.divisionname}
                                </option>
                              ))}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="card-footer border-0 pt-0 d-flex justify-content-end align-items-center">
                        <div>
                          <button
                            className="btn btn-icon btn-icon-end btn-primary"
                            type="submit"
                            onClick={handleSubmit}                         >
                            <span>Save</span>
                            <i data-acorn-icon="chevron-right" />
                          </button>
                        </div>
                      </div>
                    </div>                 
                </section>
                {/* Personal End */}
                </form>
              </div>
              {/* Content End */}
             
            </div>
            {/* Scrollspy Start */}
            <div className="col-md-auto d-none d-lg-block" id="scrollSpyMenu">
              <ul className="nav flex-column">
                <li>
                  <a className="nav-link" href="#title">
                    <i data-acorn-icon="chevron-right" />
                    <span>Getting started</span>
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#login">
                    <i data-acorn-icon="chevron-right" />
                    <span>Login Credentials for Student</span>
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#loginp">
                    <i data-acorn-icon="chevron-right" />
                    <span>Login Credentials for Parent</span>
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#info">
                    <i data-acorn-icon="chevron-right" />
                    <span>Personal Info</span>
                  </a>
                </li>
                <li>
                  <a className="nav-link" href="#assigned">
                    <i data-acorn-icon="chevron-right" />
                    <span>Class info</span>
                  </a>
                </li>
              </ul>
            </div>
            {/* Scrollspy End */}
          </div>
        </div>
      </main>

      <Shfooter />
      <ToastContainer />
    </>
  );
}

export default Shaddstudent;
