import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Shlogin() {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [schoolData, setSchoolData] = useState(null);
  const [values, setValues] = useState({
    username: "",
    password: "",
  });

  const [error, setError] = useState("");
  const navigate = useNavigate();

 async function handleSubmit(e) {
    e.preventDefault();

   await axios
      .post(apiUrl+"/schoollogin", values)
      .then((res) => {
        if (res.data === "success") {
          sessionStorage.setItem("username", values.username);
          getid(sessionStorage.getItem("username"));

          navigate("/schooldashboard");
        } else {
          toast.error("Invalid username or password");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("An error occurred. Please try again.");
      });
  }

  const getid = async (username) => {
   await axios
      .get(apiUrl+"/schoolinfo", { params: { username } })
      .then((res) => {
        setSchoolData(res.data);
        sessionStorage.setItem("uid", res.data.id);
        setValues((prevValues) => ({
          ...prevValues,
          schoolid: res.data.id,
        }));
      })
      .catch((err) => {
        console.error("Error fetching school data:", err);
      });
  };

  return (
    <>
      <Helmet>
        <title>CODEX | School | Login</title>
        <meta name="description" content="School Login Page." />
        <meta name="keywords" content="codex, school, react" />
      </Helmet>

      {/* Background Start */}
      <div className="fixed-background-school" />
      {/* Background End */}
      <div className="container-fluid p-0 h-100 position-relative">
        <div className="row g-0 h-100">
          {/* Left Side Start */}
          <div className="offset-0 col-12 d-none d-lg-flex offset-md-1 col-lg h-lg-100">
            <div className="min-h-100 d-flex align-items-center">
              <div className="w-100 w-lg-75 w-xxl-50"></div>
            </div>
          </div>
          {/* Left Side End */}
          {/* Right Side Start */}
          <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">
            <div className="sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
              <div className="sw-lg-50 px-5">
                <div className="sh-11">
                  <a href="index.html">
                    <img src="img/logo/logo.png" width="200px" />
                    {/* <div class="logo-default"></div> */}
                  </a>
                </div>
                <div className="mb-5">
                  <h2 className="cta-1 mb-0 text-primary">Welcome school,</h2>
                  <h2 className="cta-1 text-primary">let's get started!</h2>
                </div>
                <div className="mb-5">
                  <p className="h6">Please use your credentials to login.</p>
                  <p className="h6">
                    If you are not a member, please
                    <a href="#">request!</a>.
                  </p>
                </div>
                <div>
                  <form
                    className="tooltip-end-bottom"
                    noValidate=""
                    autoComplete="off"
                    onSubmit={handleSubmit}
                  >
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <i data-acorn-icon="email" />
                      <input
                        className="form-control"
                        placeholder="Username"
                        name="username"
                        autoComplete="off"
                        // onChange={e=>setUsername(e.target.value)}
                        required
                        onChange={(e) =>
                          setValues({ ...values, username: e.target.value })
                        }
                      />
                    </div>
                    <div className="mb-3 filled form-group tooltip-end-top">
                      <i data-acorn-icon="lock-off" />
                      <input
                        className="form-control pe-7"
                        name="password"
                        type="password"
                        placeholder="Password"
                        autoComplete="off"
                        // onChange={e=>setPassword(e.target.value)}
                        required
                        onChange={(e) =>
                          setValues({ ...values, password: e.target.value })
                        }
                      />
                      <a
                        className="text-small position-absolute t-3 e-3"
                        href="#"
                      >
                        Forgot?
                      </a>
                    </div>
                    <button type="submit" className="btn btn-lg btn-primary">
                      Login
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* Right Side End */}
        </div>
      </div>

      <ToastContainer />
    </>
  );
}

export default Shlogin;
