import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useNavigate   } from "react-router-dom";

function Pheader() {

  const navigate = useNavigate();
  const [student, setStudent] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    getstudentinfo();
  }, []);


  const handleLogout = (e) => {
    e.preventDefault();
    // Clear user data from local storage or session storage
    localStorage.removeItem('userToken');
    sessionStorage.removeItem('pusername');
    sessionStorage.removeItem('puid');
    sessionStorage.removeItem('haspdashRefreshed');
    
    // Redirect to login page
    navigate('/parentlogin');
  };

  const getstudentinfo = () => {

    const puid = sessionStorage.getItem("puid");
   // toast.success(puid);

     axios.get(apiUrl+'/parentinfo', { params: {puid} })
   .then(response => {
     setStudent(response.data);
   })
   .catch(error => {
     console.error('Error fetching data:', error);
   });

 };


  return (
    <>
      <div id="nav" className="nav-container d-flex">
        <div className="nav-content d-flex">
          {/* Logo Start */}
          <div className="logo position-relative">
            <a href="/parentdashboard">
              {/* Logo can be added directly */}
              <img src="img/logo/logotrans.png" alt="logo" />
              {/* Or added via css to provide different ones for different color themes */}
              {/* <div class="img"></div> */}
            </a>
          </div>
          {/* Logo End */}
          {/* Language Switch Start */}
          {/* <div className="language-switch-container">
            <button
              className="btn btn-empty language-button dropdown-toggle"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              EN
            </button>
            <div className="dropdown-menu">
              <a href="#" className="dropdown-item active">
                EN
              </a>
              <a href="#" className="dropdown-item">
                AR
              </a>
            </div>
          </div> */}
          {/* Language Switch End */}
          {/* User Menu Start */}
          <div className="user-container d-flex">
            <a
              href="#"
              className="d-flex user position-relative"
              data-bs-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              {/* <img
                className="profile"
                alt="profile"
                src="img/profile/profile-2.webp"
              /> */}
              <div className="name">{student ? student.parentname : <p>Parent Panel</p> }</div>
            </a>
            <div className="dropdown-menu dropdown-menu-end user-menu wide">
              <div className="row mb-3 ms-0 me-0">
                {/* <div className="col-12 ps-1 mb-2"> */}
                <button  className="btn btn-primary" onClick={handleLogout}> Logout </button>
                {/* </div> */}
                            
              </div>
             
            </div>
          </div>
          {/* User Menu End */}
          {/* Icons Menu Start */}
          <ul className="list-unstyled list-inline text-center menu-icons">
            {/* <li className="list-inline-item">
              <a
                href="#"
                data-bs-toggle="modal"
                data-bs-target="#searchPagesModal"
              >
                <i data-acorn-icon="search" data-acorn-size={18} />
              </a>
            </li>
            <li className="list-inline-item">
              <a href="#" id="pinButton" className="pin-button">
                <i
                  data-acorn-icon="lock-on"
                  className="unpin"
                  data-acorn-size={18}
                />
                <i
                  data-acorn-icon="lock-off"
                  className="pin"
                  data-acorn-size={18}
                />
              </a>
            </li> */}
            <li className="list-inline-item">
              <a href="#" id="colorButton">
                <i
                  data-acorn-icon="light-on"
                  className="light"
                  data-acorn-size={18}
                />
                <i
                  data-acorn-icon="light-off"
                  className="dark"
                  data-acorn-size={18}
                />
              </a>
            </li>
            <li className="list-inline-item">
              <a
                href="#"
                data-bs-toggle="dropdown"
                data-bs-target="#notifications"
                aria-haspopup="true"
                aria-expanded="false"
                className="notification-button"
              >
                <div className="position-relative d-inline-flex">
                  <i data-acorn-icon="bell" data-acorn-size={18} />
                  <span className="position-absolute notification-dot rounded-xl" />
                </div>
              </a>
              <div
                className="dropdown-menu dropdown-menu-end wide notification-dropdown scroll-out"
                id="notifications"
              >
                <div className="scroll">
                  <ul className="list-unstyled border-last-none">
                    {/* <li className="mb-3 pb-3 border-bottom border-separator-light d-flex">
                      <img
                        src="img/profile/profile-1.webp"
                        className="me-3 sw-4 sh-4 rounded-xl align-self-center"
                        alt="..."
                      />
                      <div className="align-self-center">
                        <a href="#">
                          Teacher Loisse Kaycee just sent a new comment!
                        </a>
                      </div>
                    </li>
                    <li className="mb-3 pb-3 border-bottom border-separator-light d-flex">
                      <img
                        src="img/profile/profile-2.webp"
                        className="me-3 sw-4 sh-4 rounded-xl align-self-center"
                        alt="..."
                      />
                      <div className="align-self-center">
                        <a href="#">Mid-term Assessments are published.</a>
                      </div>
                    </li>
                    <li className="mb-3 pb-3 border-bottom border-separator-light d-flex">
                      <img
                        src="img/profile/profile-3.webp"
                        className="me-3 sw-4 sh-4 rounded-xl align-self-center"
                        alt="..."
                      />
                      <div className="align-self-center">
                        <a href="#">
                          Assignment is here! Make sure your ward complete it on
                          time!
                        </a>
                      </div>
                    </li> */}
                    <li className="pb-3 pb-3 border-bottom border-separator-light d-flex">
                      {/* <img
                        src="img/profile/profile-6.webp"
                        className="me-3 sw-4 sh-4 rounded-xl align-self-center"
                        alt="..."
                      /> */}
                      <div className="align-self-center">
                  <a href="#">No new notifications for you!</a>
                </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>
          </ul>
          {/* Icons Menu End */}
          {/* Menu Start */}
          <div className="menu-container flex-grow-1">
            <ul id="menu" className="menu">
              <li>
                {/* <a href="/parentdashboard">
                  <i
                    data-acorn-icon="home"
                    className="icon"
                    data-acorn-size={18}
                  />
                  <span className="label">Dashboards</span>
                </a> */}
                <Link to="/parentdashboard" className="">
          <i data-acorn-icon="home" className="icon" data-acorn-size={18} />
          <span className="label">Dashboard</span></Link>
              </li>
              {/* <li>
                <a
                  href="codex.parent.assessment.html"
                  data-href="codex.parent.assessment.html"
                >
                  <i
                    data-acorn-icon="screen"
                    className="icon"
                    data-acorn-size={18}
                  />
                  <span className="label">Assessment</span>
                </a>
              </li> */}
              <li>
                <a
                  href="/parentassignment"
                  data-href="/parentassignment"
                >
                  <i
                    data-acorn-icon="check-square"
                    className="icon"
                    data-acorn-size={18}
                  />
                  <span className="label">Assignment</span>
                </a>
              </li>
              <li>
                <a
                  href="/parentcertificate"
                  data-href="/parentcertificate"
                >
                  <i
                    data-acorn-icon="notebook-1"
                    className="icon"
                    data-acorn-size={18}
                  />
                  <span className="label">Certificate</span>
                </a>
              </li>
              <li className="mega">
                <a
                  href="/parentleaderboard"
                  data-href="/parentleaderboard"
                >
                  <i
                    data-acorn-icon="prize"
                    className="icon"
                    data-acorn-size={18}
                  />
                  <span className="label">Leaderboard</span>
                </a>
              </li>
              <li className="mega">
                <a
                  href="/parentcompetitions"
                  data-href="/parentcompetitions"
                >
                  <i
                    data-acorn-icon="controller"
                    className="icon"
                    data-acorn-size={18}
                  />
                  <span className="label">Competitions</span>
                </a>
              </li>
            </ul>
          </div>
          {/* Menu End */}
          {/* Mobile Buttons Start */}
          <div className="mobile-buttons-container">
            {/* Scrollspy Mobile Button Start */}
            <a
              href="#"
              id="scrollSpyButton"
              className="spy-button"
              data-bs-toggle="dropdown"
            >
              <i data-acorn-icon="menu-dropdown" />
            </a>
            {/* Scrollspy Mobile Button End */}
            {/* Scrollspy Mobile Dropdown Start */}
            <div
              className="dropdown-menu dropdown-menu-end"
              id="scrollSpyDropdown"
            />
            {/* Scrollspy Mobile Dropdown End */}
            {/* Menu Button Start */}
            <a href="#" id="mobileMenuButton" className="menu-button">
              <i data-acorn-icon="menu" />
            </a>
            {/* Menu Button End */}
          </div>
          {/* Mobile Buttons End */}
        </div>
        <div className="nav-shadow" />
      </div>
    </>
  );
}

export default Pheader;
