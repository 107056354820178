import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Shheader from "./Shheader";
import Shfooter from "./Shfooter";
import { format } from "date-fns";
import useScript from "../hooks/useScript";
import CryptoJS from 'crypto-js';


function Shsupportmain() {



    const [username, setUsername] = useState("");
    const apiUrl = process.env.REACT_APP_API_URL;
    const [u_id, setu_id] = useState("");
    const navigate = useNavigate();
    const [schoolData, setSchoolData] = useState(null);
    const [supporttickets, setSupporttickets] = useState([]);
    const [values, setValues] = useState({
        schoolid: "",
        username: "",
      });
    

    useEffect(() => {
       
      const storedUsername = sessionStorage.getItem('username');
      const hasRefreshedsupport = sessionStorage.getItem('hasRefreshedsupport');
      //const uux = sessionStorage.getItem("uid");
      //toast.success(uux);
      // setUsername(storedUsername);
      // setu_id(sessionStorage.getItem("uid"));
      setUsername(storedUsername);
      setu_id(sessionStorage.getItem("uid"));
      
      // else {
      // const storedUsername = sessionStorage.getItem('username');

      if (storedUsername) {
        if (!hasRefreshedsupport) {
          
          fetchSchoolData(storedUsername);
          fetchSupporttickets();
          //setUsername(storedUsername);
          //setu_id(sessionStorage.getItem("uid"));
          //const storedUsername = sessionStorage.getItem('username');
          sessionStorage.setItem('hasRefreshedsupport', 'true');
          window.location.reload();
        } 
        else{
          
          fetchSchoolData(storedUsername);
          fetchSupporttickets();
        }
        
      }
      else{
        navigate("/schoollogin");
      }   
    // }

      }, [navigate]);







      const fetchSchoolData = async (username) => {
       await axios
          .get(apiUrl+"/schoolinfo", { params: { username } })
          .then((res) => {
            setSchoolData(res.data);
            sessionStorage.setItem('uid', res.data.id);
            setValues((prevValues) => ({
              ...prevValues,
              schoolid: res.data.id,
            }));
          })
          .catch((err) => {
            console.error("Error fetching school data:", err);
          });
      };


      const fetchSupporttickets = async () => {
        
        const uu = sessionStorage.getItem("uid")
        // toast.success("fetchSupporttickets:"+uu);
       await  axios
          .get(apiUrl+"/schoolsupportticketsbyschool", { params: { schoolid : uu } })
          .then((res) => {
            setSupporttickets(res.data);
          })
          .catch((err) => {
            console.error("Error fetching support ticket data:", err);
          });
      };


    //   const updatesdata = async (schoolid) => {
    //     try {
    //       await axios.post("/updatestudentscount",null, { params: { schoolid : schoolid} });
    //       // toast.success(`School ID: ${schoolid} updated successfully`);
    //       console.log('Update successful');
    //     } catch (error) {
    //       console.error('Update Error:', error);
    //       // toast.error(`Error now: ${error} `);
    //     }
    //   };


  return (

    <>
    <Helmet>
      <title>CODEX | School | Support</title>
      <meta name="description" content="View Support Tickets" />
      <meta name="keywords" content="codex, school, react" />
      {/* <script src="js/plugins/datatable.editableboxed.js" async></script> */}
    </Helmet>

    <Shheader />

    <main>
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="page-title-container">
          {/* <h1 class="mb-0 pb-0 display-4" id="title">Teachers</h1> */}
          <nav
            className="breadcrumb-container d-inline-block"
            aria-label="breadcrumb"
          >
       {schoolData ? (
                    <h3 className="mb-0 pb-0 display-6" id="title">
                      Welcome {schoolData.schoolname}!
                    </h3>
                  ) : (
                    <h3 className="mb-0 pb-0 display-6" id="title">
                      {"Loading school data..."}
                    </h3>
                  )}
          </nav>
        </div>
      </div>
    </div>
    <div className="col">
      {/* Title and Top Buttons Start */}
      <div className="page-title-container mb-3">
        <div className="row">
          {/* Title Start */}
          <div className="col mb-2">
            <h1 className="mb-2 pb-0 display-4" id="title">
              Support Tickets
            </h1>
            <div className="text-muted font-heading text-small">
              Let us manage the applications so you can focus on cool things.
            </div>
          </div>
          {/* Title End */}
          {/* Top Buttons Start */}
          <div className="col-12 col-sm-auto d-flex align-items-center justify-content-end">
            {/* Add New Button Start */}
            {/* <button
              type="button"
              className="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"
            >
              <i data-acorn-icon="plus" />
              <span>New Ticket</span>
            </button> */}
            <a href="/schoolnewticket"  className="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"> <i data-acorn-icon="plus" />
            <span>New Ticket</span></a>
            {/* schoolnewticket */}
            {/* Add New Button End */}
          </div>
          {/* Top Buttons End */}
        </div>
      </div>
      {/* Title and Top Buttons End */}
      {/* Controls Start */}
     
      {/* Controls End */}
      {/* Ticket List Start */}
      <div className="row">
        <div className="col-12 mb-5">
          <div className="card mb-2 bg-transparent no-shadow d-none d-md-block">
            <div className="row g-0 sh-3">
              <div className="col">
                <div className="card-body pt-0 pb-0 h-100">
                  <div className="row g-0 h-100 align-content-center">
                    <div className="col-12 col-md-2 d-flex align-items-center mb-2 mb-md-0 text-muted text-medium">
                      TICKET ID
                    </div>
                    <div className="col-6 col-md-6 d-flex align-items-center text-alternate text-medium text-muted text-medium">
                      TITLE
                    </div>
                    <div className="col-6 col-md-3 d-flex align-items-center text-alternate text-medium text-muted text-medium">
                      CATEGORY
                    </div>
                    <div className="col-6 col-md-1 d-flex align-items-center justify-content-end text-alternate text-medium text-muted text-medium">
                      STATUS
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="mb-n2">
          {supporttickets.map((supportticket, index) => (
            <a
            href={`/schoolshowthread?ticketid=${supportticket.ticketid}`}
              className="card mb-2 sh-22 sh-md-8 hover-border-primary"
            >
              <div className="card-body pt-0 pb-0 h-100">
                <div className="row g-0 h-100 align-content-center">
                  <div className="col-6 col-md-2 d-flex flex-column justify-content-center mb-2 mb-md-0 order-1 order-md-1">
                    <div className="text-muted text-small d-md-none">Id</div>
                    <div className="text-body fw-bold">{supportticket.ticketid}</div>
                  </div>
                  <div className="col-12 col-md-6 d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                    <div className="text-muted text-small d-md-none">Title</div>
                    <div className="text-body fw-bold">{supportticket.title}</div>
                  </div>
                  <div className="col-12 col-md-3 d-flex flex-column justify-content-center mb-2 mb-md-0 order-4 order-md-3">
                    <div className="text-muted text-small d-md-none">Category</div>
                    <div className="text-alternate fw-bold">{supportticket.categoryname}</div>
                  </div>
                  <div className="col-6 col-md-1 d-flex flex-column justify-content-center align-items-md-end mb-2 mb-md-0 order-2 order-md-5">
                    <div className="text-muted text-small d-md-none">
                      Status
                    </div>
                    <div className="text-alternate">
                      <span className="badge rounded-pill bg-outline-primary">
                      {supportticket.statusname}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </a>

))}

            {/* <a
              href="codex.school.ticketdetails.html"
              className="card mb-2 sh-22 sh-md-8 hover-border-primary"
            >
              <div className="card-body pt-0 pb-0 h-100">
                <div className="row g-0 h-100 align-content-center">
                  <div className="col-6 col-md-1 d-flex flex-column justify-content-center mb-2 mb-md-0 order-1 order-md-1">
                    <div className="text-muted text-small d-md-none">Id</div>
                    <div className="text-body fw-bold">3127</div>
                  </div>
                  <div className="col-12 col-md-6 d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                    <div className="text-muted text-small d-md-none">Title</div>
                    <div className="text-body fw-bold">
                      Are the services offline?
                    </div>
                  </div>
                  <div className="col-12 col-md-3 d-flex flex-column justify-content-center mb-2 mb-md-0 order-4 order-md-3">
                    <div className="text-muted text-small d-md-none">
                      Category
                    </div>
                    <div className="text-alternate fw-bold">Database</div>
                  </div>
                  <div className="col-6 col-md-2 d-flex flex-column justify-content-center align-items-md-end mb-2 mb-md-0 order-2 order-md-5">
                    <div className="text-muted text-small d-md-none">
                      Status
                    </div>
                    <div className="text-alternate">
                      <span className="badge rounded-pill bg-outline-primary">
                        ACTIVE
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </a>
            <a
              href="codex.school.ticketdetails.html"
              className="card mb-2 sh-22 sh-md-8 hover-border-primary"
            >
              <div className="card-body pt-0 pb-0 h-100">
                <div className="row g-0 h-100 align-content-center">
                  <div className="col-6 col-md-1 d-flex flex-column justify-content-center mb-2 mb-md-0 order-1 order-md-1">
                    <div className="text-muted text-small d-md-none">Id</div>
                    <div className="text-muted">3044</div>
                  </div>
                  <div className="col-12 col-md-6 d-flex flex-column justify-content-center mb-2 mb-md-0 order-3 order-md-2">
                    <div className="text-muted text-small d-md-none">Title</div>
                    <div className="text-muted">Extending storage</div>
                  </div>
                  <div className="col-12 col-md-3 d-flex flex-column justify-content-center mb-2 mb-md-0 order-4 order-md-3">
                    <div className="text-muted text-small d-md-none">
                      Category
                    </div>
                    <div className="text-muted">Storage</div>
                  </div>
                  <div className="col-6 col-md-2 d-flex flex-column justify-content-center align-items-md-end mb-2 mb-md-0 order-2 order-md-5">
                    <div className="text-muted text-small d-md-none">
                      Status
                    </div>
                    <div className="text-alternate">
                      <span className="badge rounded-pill bg-outline-muted">
                        SOLVED
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </a> */}

          </div>
        </div>
      </div>
      {/* Ticket List End */}
      {/* Pagination Start */}
      {/* <div className="d-flex justify-content-center">
        <nav>
          <ul className="pagination">
            <li className="page-item disabled">
              <a
                className="page-link shadow"
                href="#"
                tabIndex={-1}
                aria-disabled="true"
              >
                <i data-acorn-icon="chevron-left" />
              </a>
            </li>
            <li className="page-item active">
              <a className="page-link shadow" href="#">
                1
              </a>
            </li>
            <li className="page-item">
              <a className="page-link shadow" href="#">
                2
              </a>
            </li>
            <li className="page-item">
              <a className="page-link shadow" href="#">
                3
              </a>
            </li>
            <li className="page-item">
              <a className="page-link" href="#">
                <i data-acorn-icon="chevron-right" />
              </a>
            </li>
          </ul>
        </nav>
      </div> */}
      {/* Pagination End */}
    </div>
  </div>
</main>


    <Shfooter />
    <ToastContainer />
    </>
  )
}

export default Shsupportmain