import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Shheader from "./Shheader";
import Shfooter from "./Shfooter";
import { format } from "date-fns";
import useScript from "../hooks/useScript";
import CryptoJS from "crypto-js";
import Shquicksidemenu from "./Shquicksidemenu";

function Shprofile() {
  const [username, setUsername] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const [u_id, setu_id] = useState("");
  const navigate = useNavigate();
  const [schoolData, setSchoolData] = useState(null);
  const [values, setValues] = useState({
    schoolid: "",
    username: "",
    schoolname: "",
    phonenumber: "",
  });
  const [formValues, setFormValues] = useState({
    userName: "",
    schoolName: "",
    eMail: "",
    phoneNumber: "",
    newPwd: "",
    pwdAgain: "",
  });

  const [errors, setErrors] = useState({
    userName: "",
    schoolName: "",
    eMail: "",
    phoneNumber: "",
    newPwd: "",
    pwdAgain: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const validateForm = () => {
    let formErrors = {};
    let isValid = true;

    if (!formValues.userName) {
      isValid = false;
      formErrors.userName = "User name is required";
    }

    if (!formValues.schoolName) {
      isValid = false;
      formErrors.schoolName = "School name is required";
    }

    setErrors(formErrors);
    return isValid;
  };

  useEffect(() => {
    const storedUsername = sessionStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
      setu_id(sessionStorage.getItem("uid"));
      fetchSchoolData(storedUsername);
      //fetchcompetitions();
      //fetchpastcompetitions();
    } else {
      navigate("/schoollogin");
    }
  }, [navigate]);

  const fetchSchoolData = (username) => {
    axios
      .get(apiUrl+"/schoolinfo", { params: { username } })
      .then((res) => {
        setSchoolData(res.data);
        sessionStorage.setItem("uid", res.data.id);
        setFormValues({
          userName: res.data.username,
          schoolName: res.data.schoolname,
          eMail: res.data.email,
          phoneNumber: res.data.phonenumber,
          newPwd: "",
          pwdAgain: "",
        });
      })
      .catch((err) => {
        console.error("Error fetching school data:", err);
      });
  };

  const handleinfoSubmit = (e) => {
    e.preventDefault();
    // toast.success(formValues);
    if (validateForm()) {
      axios
        .post(apiUrl+"/updateUserAndSchool", {
          ...formValues,
          id: sessionStorage.getItem("uid"),
        })
        .then((response) => {
          sessionStorage.setItem("username", formValues.userName);
          toast.success("Information updated successfully!");
          console.log("Update successful:", response.data);
        })
        .catch((error) => {
          toast.error("Error updating information. Please try again.");
          console.error("Error updating information:", error);
        });
    }
  };


  const handlecontactSubmit = (e) => {
    e.preventDefault();
      // toast.success(formValues);
      if (validateForm()) {
        axios
          .post(apiUrl+"/updateEmailAndPhone", {
            ...formValues,
            id: sessionStorage.getItem("uid"),
          })
          .then((response) => {
            //sessionStorage.setItem("username", formValues.userName);
            toast.success("Information updated successfully!");
            console.log("Update successful:", response.data);
          })
          .catch((error) => {
            toast.error("Error updating information. Please try again.");
            console.error("Error updating information:", error);
          });
      }    

};


const handlepwdSubmit = (e) => {
  e.preventDefault();
  if (formValues.newPwd !== formValues.pwdAgain) {
    toast.error("Passwords do not match!");
    //setError('Passwords do not match');
    return;
  }
  else{

    try {
      const response = axios.post('/updatepassword', {
          //password: formValues.newPwd
          ...formValues,
          id: sessionStorage.getItem("uid"),
      });
     // setSuccess('Password updated successfully');
     // setError('');
     toast.success("Password updated successfully");
  } catch (err) {
     // setError('Error updating password');
     // setSuccess('');
     toast.error("Error updating password!");
  }

  }


};

  return (
    <>
      <Helmet>
        <title>CODEX | School | Competitions</title>
        <meta name="description" content="View Competitions" />
        <meta name="keywords" content="codex, school, react" />
        {/* <script src="js/plugins/datatable.editableboxed.js" async></script> */}
      </Helmet>

      <Shheader />

      <main>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-title-container">
                {/* <h1 class="mb-0 pb-0 display-4" id="title">Teachers</h1> */}
                <nav
                  className="breadcrumb-container d-inline-block"
                  aria-label="breadcrumb"
                >
                  {schoolData ? (
                    <h3 className="mb-0 pb-0 display-6" id="title">
                      Welcome {schoolData.schoolname}!
                    </h3>
                  ) : (
                    <h3 className="mb-0 pb-0 display-6" id="title">
                      {"Loading school data..."}
                    </h3>
                  )}
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {/* Content Start */}
              <div className="row">
                {/* Left Side Start */}
                <div className="col-12 col-xl-4 col-xxl-3">
                  {/* Biography Start */}
                  <h2 className="small-title">Profile</h2>
                  <div className="card mb-5">
                    <div className="card-body">
                      <div className="d-flex align-items-center flex-column mb-4">
                        <div className="d-flex align-items-center flex-column">
                          <div className="sw-13 position-relative mb-3">
                            <div className="h5 mb-0">
                              <strong>Quick Menu</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Shquicksidemenu />
                    </div>
                  </div>
                  {/* Biography End */}
                </div>
                {/* Left Side End */}
                {/* Right Side Start */}
                <div className="col-12 col-xl-8 col-xxl-9 mb-5 tab-content">
                  <h2 className="small-title">Public Info</h2>
                  <div className="card mb-5">
                    <div className="card-body">
                      <form onSubmit={handleinfoSubmit}>
                        <input
                          type="hidden"
                          name="id"
                          value={sessionStorage.getItem("uid")}
                        />
                        <div className="mb-3 row">
                          <label className="col-lg-2 col-md-3 col-sm-4 col-form-label">
                            School Name
                          </label>
                          <div className="col-sm-8 col-md-9 col-lg-10">
                            <input
                              type="text"
                              className="form-control"
                              id="schoolName"
                              name="schoolName"
                              value={formValues.schoolName}
                              // value={schoolData ? schoolData.schoolname : ""}
                              onChange={handleInputChange}
                              placeholder={schoolData ? "" : "Loading..."}
                              required
                            />
                            {errors.schoolName && (
                              <div className="text-danger">
                                {errors.schoolName}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-lg-2 col-md-3 col-sm-4 col-form-label">
                            User Name
                          </label>
                          <div className="col-sm-8 col-md-9 col-lg-10">
                            <input
                              type="text"
                              className="form-control"
                              id="userName"
                              name="userName"
                              value={formValues.userName}
                              onChange={handleInputChange}
                              //value={schoolData ? schoolData.username : ""}
                              placeholder={schoolData ? "" : "Loading..."}
                              required
                            />
                            {/* {errors.userName && <div className="text-danger">{errors.userName}</div>} */}
                          </div>
                        </div>


                        <div className="mb-3 row mt-5">
                          <div className="col-sm-8 col-md-9 col-lg-10 ms-auto">
                            <button
                              type="submit"
                              className="btn btn-outline-primary"
                            >
                              Update
                            </button>
                          </div>
                        </div>

                      </form>
                    </div>
                  </div>
                  {/* Public Info End */}
                  {/* Contact Start */}
                  <h2 className="small-title">Contact</h2>
                  <div className="card mb-5">
                    <div className="card-body">


                      <form onSubmit={handlecontactSubmit}>

                      <input
                          type="hidden"
                          name="id"
                          value={sessionStorage.getItem("uid")}
                        />


                        <div className="mb-3 row">
                          <label className="col-lg-2 col-md-3 col-sm-4 col-form-label">
                            Primary Email
                          </label>
                          <div className="col-sm-8 col-md-9 col-lg-10">
                            <input
                              type="email"
                              className="form-control"
                              id="eMail"
                              name="eMail"
                              value={formValues.eMail}
                              onChange={handleInputChange}
                              placeholder={schoolData ? "" : "Loading..."}
                              required
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-lg-2 col-md-3 col-sm-4 col-form-label">
                            Phone
                          </label>
                          <div className="col-sm-8 col-md-9 col-lg-10">
                            <input
                              type="text"
                              className="form-control"
                              id="phoneNumber"
                              name="phoneNumber"
                              value={formValues.phoneNumber}
                              onChange={handleInputChange}
                              placeholder={schoolData ? "" : "Loading..."}
                              required
                            />
                          </div>
                        </div>
                        <div className="mb-3 row mt-5">
                          <div className="col-sm-8 col-md-9 col-lg-10 ms-auto">
                            <button
                              type="submit"
                              className="btn btn-outline-primary"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </form>



                    </div>
                  </div>
                  {/* Contact End */}
                  {/* Password Start */}
                  <h2 className="small-title">Password</h2>
                  <div className="card mb-5">
                    <div className="card-body">
                      <form onSubmit={handlepwdSubmit}>
                      <input
                          type="hidden"
                          name="id"
                          value={sessionStorage.getItem("uid")}
                        />
                        <div className="mb-3 row">
                          <label className="col-lg-2 col-md-3 col-sm-4 col-form-label">
                            New Password
                          </label>
                          <div className="col-sm-8 col-md-9 col-lg-10">
                            <input
                              name="newPwd"
                              type="text"
                              className="form-control"
                              value={formValues.newPwd}
                              onChange={handleInputChange}
                              placeholder={schoolData ? "" : "Loading..."}
                              required
                            />
                          </div>
                        </div>
                        <div className="mb-3 row">
                          <label className="col-lg-2 col-md-3 col-sm-4 col-form-label">
                            Password again
                          </label>
                          <div className="col-sm-8 col-md-9 col-lg-10">
                            <input
                              name="pwdAgain"
                              type="text"
                              className="form-control"
                              value={formValues.pwdAgain}
                              onChange={handleInputChange}
                              placeholder={schoolData ? "" : "Loading..."}
                              required
                            />
                          </div>
                        </div>
                        <div className="mb-3 row mt-5">
                          <div className="col-sm-8 col-md-9 col-lg-10 ms-auto">
                            <button
                              type="submit"
                              className="btn btn-outline-primary"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                  {/* Password End */}
                </div>
                {/* Right Side End */}
              </div>
              {/* Content End */}
            </div>
          </div>
        </div>
      </main>

      <Shfooter />
      <ToastContainer />
    </>
  );
}

export default Shprofile;
