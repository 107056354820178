import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pheader from "../parent/Pheader";
import Pfooter from "../parent/Pfooter";
import { format } from "date-fns";

function Pdashboard() {

  const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [student, setStudent] = useState();
    const [parentdata, setParentdata] = useState();
    const [dashstats, setDashstats] = useState(null);
    const [assignments, setAssignments] = useState([null]);
    const [values, setValues] = useState({
      puid: "",
      psid: "",
    });
    

    useEffect(() => {

        const haspdashRefreshed = sessionStorage.getItem("haspdashRefreshed");
             
        const storedUsername = sessionStorage.getItem("pusername");
        //const puid = sessionStorage.getItem("puid"); 
        getpuid(storedUsername);
        //toast.success(apiUrl);
        if (storedUsername) {
          getstudentinfo();
          getdashboadstats();
        }
        else {
          navigate("/parentlogin");
        }
        

        


        if (!haspdashRefreshed) {
            getstudentinfo();
            getdashboadstats();
            sessionStorage.setItem("haspdashRefreshed", "true");
            window.location.reload();
        }
        else
        {
           
            getstudentinfo();
            getdashboadstats();
        

        }


    }, []);

    
    // const getpuid = async (storedUsername) => {


    //   await axios
    //   .get(apiUrl+"/parentinfoonlogin", { params: { storedUsername } })
    //   .then((res) => {
    //     setParentdata(res.data);
    //     sessionStorage.setItem("puid", res.data.id);
    //     sessionStorage.setItem("pschoolid", res.data.schoolid);
    //    // toast.success(sessionStorage.getItem("puid")+sessionStorage.getItem("pschoolid")+storedUsername);
    //     setValues((prevValues) => ({
    //       ...prevValues,
    //       puid: res.data.id,
    //       psid: res.data.schoolid,
    //     }));
    //    toast.success("......"+values.puid+"......."+values.psid);
    //   })
    //   .catch((err) => {
    //     console.error("Error fetching parent data:", err);
    //   });

    // };

    const getpuid = async (storedUsername) => {
      try {

        const pusername = storedUsername;
        await  axios
        .get(apiUrl+"/parentinfoonlogin", { params: { pusername } })
        .then((res) => {
          sessionStorage.setItem("puid", res.data.id);
          sessionStorage.setItem("pschoolid", res.data.schoolid);

          //toast.success("......" + res.data.id + "......." + res.data.schoolid);
        })
        .catch((err) => {
          console.error("Error fetching school data:", err);
        });


       } catch (err) {
         console.error("Error fetching parent data:", err);
       }
    };
    

    const getassignments =  async () => {

         const grade = await sessionStorage.getItem("grade");
         //student.grade;
         const division = await sessionStorage.getItem("division");
         //student.division;

      //  toast.success(" "+grade+division);

       await axios.get(apiUrl+'/getassignmentsforparent', {params: { grade, division} })
      .then(response => {
        setAssignments(response.data);

      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

    };


    const getdashboadstats = async () => {
        const puid = await sessionStorage.getItem("puid");
       await axios.get(apiUrl+'/parentdashboardstats', { params: {puid} })
       .then(response =>  {
       setDashstats(response.data);
       })
      .catch(error => {
        console.error('Error fetching data:', error);
      });
    };


    const getstudentinfo = async () => {

       const puid = sessionStorage.getItem("puid");
       //toast.success(sessionStorage.getItem("puid"));
      await axios.get(apiUrl+'/parentinfo', { params: {puid} })
      .then(response => {
        setStudent(response.data);
        sessionStorage.setItem("grade", response.data.grade);
        sessionStorage.setItem("division", response.data.division);
       // getassignments();
      })
      .catch(error => {
        console.error('Error fetching data:', error);
      });

    };


    



  return (
    <>
      <Helmet>
        <title>CODEX | Parent | Dashboard</title>
        <meta name="description" content="Parent Dashboard Page." />
        <meta name="keywords" content="codex, parent, react" />
      </Helmet>

      <Pheader />




      <main>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-title-container">
                <h1 className="mb-0 pb-0 display-4" id="title">
                  Dashboard 
                </h1>
                <nav
                  className="breadcrumb-container d-inline-block"
                  aria-label="breadcrumb"
                >
                  <ul className="breadcrumb pt-0">
                    <li className="breadcrumb-item">
                      <a href="#">Home</a>
                    </li>
                    <li className="breadcrumb-item">
                      <a href="#">Dashboards</a>
                    </li>
                  </ul>
                  <h3 className="mb-0 pb-0 display-6" id="title">
                    Welcome parent of {student ? student.studentname : <p>No data available</p> }!
                  </h3>
                   {/* {assignments.title} */}
                </nav>
              </div>
            </div>
          </div>



          <div className="row">
            <div className="col-12 col-lg-8">
              {/* Inventory Start */}
              <h2 className="small-title">Quick Statistics</h2>
              <div className="mb-5">
                <div className="row g-2">
                  <div className="col-12 col-sm-6 col-lg-3">
                    <div className="card hover-scale-up cursor-pointer sh-19">
                      <div className="h-100 d-flex flex-column justify-content-between card-body align-items-center">
                        <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                          <i data-acorn-icon="crown" className="text-white" />
                        </div>
                        <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                        {student ? student.points : <p>-</p> }
                        </div>
                        <div className="text text-primary">Total Points</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <div className="card hover-scale-up cursor-pointer sh-19">
                      <div className="h-100 d-flex flex-column justify-content-between card-body align-items-center">
                        <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                          <i
                            data-acorn-icon="dashboard-1"
                            className="text-white"
                          />
                        </div>
                        <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                        {student ? student.learning : <p>No data available</p> }%
                        </div>
                        <div className="text text-primary">Learning Path</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <div className="card hover-scale-up cursor-pointer sh-19">
                      <div className="h-100 d-flex flex-column justify-content-between card-body align-items-center">
                        <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                          <i data-acorn-icon="tent" className="text-white" />
                        </div>
                        <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                        {dashstats ? dashstats.competitionCount : <p>-</p> }
                        </div>
                        <div className="text text-primary">Competition</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <div className="card hover-scale-up cursor-pointer sh-19">
                      <div className="h-100 d-flex flex-column justify-content-between card-body align-items-center">
                        <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                          <i
                            data-acorn-icon="calendar"
                            className="text-white"
                          />
                        </div>
                        <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                          -
                        </div>
                        <div className="text text-primary">Course Expiry</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Inventory End */}
              {/* assignment Start */}
              <div className="col-lg-12 mb-5">
                <div className="d-flex justify-content-between">
                 

                <div className="row g-2 mb-5">
            <div className="col-12 col-sm-6 col-xl-3">
              <div className="card w-100 sh-23 hover-img-scale-up">
                <img
                  src="img/banner/bnr.jpg"
                  className="card-img h-100 scale"
                  alt="card image"
                />
                <div className="card-img-overlay d-flex flex-column justify-content-between bg-transparent">
                  <div className="d-flex flex-column h-100 justify-content-between align-items-start">
                    <div className="cta-2 text-black w-75">
                      Assignment Marks of your ward.
                    </div>
                    <a
                      href="#"
                      className="btn btn-icon btn-icon-start btn-primary mt-3 stretched-link"
                    >
                      <i
                        data-acorn-icon="chevron-right"
                        className="text-white"
                      />
                      <span>View</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-3">
              <div className="card w-100 sh-23 hover-img-scale-up">
                <img
                  src="img/banner/bnr.jpg"
                  className="card-img h-100 scale"
                  alt="card image"
                />
                <div className="card-img-overlay d-flex flex-column justify-content-between bg-transparent">
                  <div className="d-flex flex-column h-100 justify-content-between align-items-start">
                    <div className="cta-2 text-black w-75">
                      Certificate assigned to ward.
                    </div>
                    <a
                      href="/parentcertificate"
                      className="btn btn-icon btn-icon-start btn-primary mt-3 stretched-link"
                    >
                      <i
                        data-acorn-icon="chevron-right"
                        className="text-white"
                      />
                      <span>View</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-3">
              <div className="card w-100 sh-23 hover-img-scale-up">
                <img
                  src="img/banner/bnr.jpg"
                  className="card-img h-100 scale"
                  alt="card image"
                />
                <div className="card-img-overlay d-flex flex-column justify-content-between bg-transparent">
                  <div className="d-flex flex-column h-100 justify-content-between align-items-start">
                    <div className="cta-2 text-black w-75">
                      Contact the teacher of your ward
                    </div>
                    <a
                      href="#"
                      className="btn btn-icon btn-icon-start btn-primary mt-3 stretched-link"
                    >
                      <i
                        data-acorn-icon="chevron-right"
                        className="text-white"
                      />
                      <span>View</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-xl-3">
              <div className="card w-100 sh-23 hover-img-scale-up">
                <img
                  src="img/banner/bnr.jpg"
                  className="card-img h-100 scale"
                  alt="card image"
                />
                <div className="card-img-overlay d-flex flex-column justify-content-between bg-transparent">
                  <div className="d-flex flex-column h-100 justify-content-between align-items-start">
                    <div className="cta-2 text-black w-75">
                      Our latest open competitions
                    </div>
                    <a
                      href="/parentcompetitions"
                      className="btn btn-icon btn-icon-start btn-primary mt-3 stretched-link"
                    >
                      <i
                        data-acorn-icon="chevron-right"
                        className="text-white"
                      />
                      <span>View</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>



                </div>
             
              </div>
              {/* assignment End */}
            </div>
            <div className="col-12 col-lg-4">
              {/* Today's Orders Start */}
              <h2 className="small-title">Quick Info</h2>
              <div className="card w-100 sh-50 mb-5">
                <img
                  src="img/banner/cta-square-4.jpg"
                  className="card-img h-100"
                  alt="card image"
                />
                <div className="card-img-overlay d-flex flex-column justify-content-between bg-transparent">
                  <div className="d-flex flex-column h-100 justify-content-between align-items-start">
                    <div>
                      <div className="cta-1 text-primary mb-1"> {dashstats ? dashstats.assignmentCount : <p>-</p> }</div>
                      <div className="lh-1-25 mb-0">New Assignment</div>
                    </div>
                    <div>
                      <div className="cta-1 text-primary mb-1">NIL</div>
                      <div className="lh-1-25 mb-0">Certificate available</div>
                    </div>
                    <div>
                      <div className="cta-1 text-primary mb-1">0</div>
                      <div className="lh-1-25 mb-0">Pending Messages</div>
                    </div>
                    <div>
                      <div className="cta-1 text-primary mb-1">{student ? student.learning : <p>No data available</p> }%</div>
                      <div className="lh-1-25 mb-0">Course Completed</div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Today's Orders End */}
              {/* Categories Start */}
              {/* <h2 className="small-title">Dates to remember</h2>
              <div className="card mb-5 h-auto sh-lg-23">
                <div className="card-body h-100">
                  <div className="row g-0 h-100">
                    <div className="col-12 col-sm-6 h-100 d-flex justify-content-between flex-column">
                      <a
                        href="#"
                        className="body-link d-flex mb-2"
                        style={{ color: "green !important" }}
                      >
                        12th April : School Fee
                      </a>
                      <a
                        href="#"
                        className="body-link d-flex mb-2"
                        style={{ color: "green !important" }}
                      >
                        12th April : Bus Fee
                      </a>
                      <a
                        href="#"
                        className="body-link d-flex mb-2"
                        style={{ color: "orchid !important" }}
                      >
                        15th April : Sports Day
                      </a>
                      <a
                        href="#"
                        className="body-link d-flex mb-2"
                        style={{ color: "orchid !important" }}
                      >
                        16th April : Sports Day
                      </a>
                    </div>
                    <div className="col-12 col-sm-6 h-100 d-flex justify-content-between flex-column">
                      <a
                        href="#"
                        className="body-link d-flex mb-2"
                        style={{ color: "orchid !important" }}
                      >
                        21st April : Quiz Competition
                      </a>
                      <a
                        href="#"
                        className="body-link d-flex mb-2"
                        style={{ color: "red !important" }}
                      >
                        22nd April : Holiday
                      </a>
                      <a
                        href="#"
                        className="body-link d-flex mb-2"
                        style={{ color: "red !important" }}
                      >
                        23rd April : Holiday
                      </a>
                      <a
                        href="#"
                        className="body-link d-flex mb-2"
                        style={{ color: "orchid !important" }}
                      >
                        30 April : One Day Tour
                      </a>
                    </div>
                  </div>
                </div>
              </div> */}
              {/* Categories End */}
            </div>
          </div>
      
        </div>
      </main>

      <Pfooter />
      <ToastContainer />
    </>
  );
}

export default Pdashboard;
