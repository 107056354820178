import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { format } from "date-fns";
import Theader from "./Theader";
import Tfooter from "./Tfooter";
import { ClipLoader } from "react-spinners"; // Import the spinner

function Tdashboard() {
  const [username, setUsername] = useState("");
  const [loading, setLoading] = useState(true); // Add loading state
  const navigate = useNavigate();
  const [schoolData, setSchoolData] = useState(null);
  const [teacherData, setTeacherData] = useState(null);
  const [studentCount, setStudentCount] = useState(null);
  const [assignmentCount, setAssignmentCount] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    const storedUsername = sessionStorage.getItem("tusername");
    const storedSchoolid = sessionStorage.getItem("schoolid");
    const storedTeacherid = sessionStorage.getItem("tuid");
    const hasRefreshedd = sessionStorage.getItem("hasRefreshedd");

    if (storedUsername) {
      if (!hasRefreshedd) {
       // setUsername(storedUsername);
        // fetchSchoolData(storedUsername);

        sessionStorage.setItem("hasRefreshedd", "true");
        window.location.reload();
      } else {
       // setUsername(storedUsername);
        fetchSchoolData(storedSchoolid);
        fetchTeacherData(storedTeacherid);
      }
    } else {
      navigate("/teacherlogin");
    }
    //}
  }, [navigate]);

  const fetchSchoolData = async (id) => {
    setLoading(true);
    try {
        const res = await axios.get(apiUrl + "/schoolinfobyteacherid", { params: { id } });
        setSchoolData(res.data);
    } catch (err) {
        console.error("Error fetching school data:", err);
    } finally {
        setLoading(false);
    }
};

const fetchStudentCount = async (grade, division) => {
    try {
        const res = await axios.get(apiUrl + "/studentcountbyteacher", { params: { grade, division } });
        setStudentCount(res.data.student_count);
    } catch (err) {
        console.error("Error fetching student count:", err);
    }
};


const fetchAssignmentCount = async (id) => {
    try {
        const res = await axios.get(apiUrl + "/assignmentcountbyteacher", { params: { id } });
        setAssignmentCount(res.data.assignment_count);
    } catch (err) {
        console.error("Error fetching student count:", err);
    }
};



const fetchTeacherData = async (id) => {
    setLoading(true);
    try {
        const res = await axios.get(apiUrl + "/teacherinfobyteacherid", { params: { id } });
        setTeacherData(res.data);
        await fetchStudentCount(res.data.grade, res.data.division); 
        await fetchAssignmentCount(res.data.id); 

    } catch (err) {
        console.error("Error fetching teacher data:", err);
    } finally {
        setLoading(false);
    }
};


// if (loading) {
//     return <div>Loading...</div>; // Show a loading indicator while fetching data
// }
  return (
    <>
      <Helmet>
        <title>CODEX | Teacher | Dashboard</title>
        <meta name="description" content="Teacher Dashboard Page." />
        <meta name="keywords" content="codex, school, react" />
      </Helmet>

      <Theader />

      <main>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-title-container">
                <h1 className="mb-0 pb-0 display-4" id="title">
                  Dashboard
                </h1>
                <div>
                  {loading ? (
                    <ClipLoader size={50} color={"#123abc"} loading={loading} /> // Show loading spinner
                  ) : (
                    <nav
                      className="breadcrumb-container d-inline-block"
                      aria-label="breadcrumb"
                    >
                      <ul className="breadcrumb pt-0">
                        <li className="breadcrumb-item">
                          <a href="#">Home</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="#">Dashboards</a>
                        </li>
                      </ul>

                      {teacherData ? (
                        <h3 className="mb-0 pb-0 display-6" id="title">
                          Welcome {teacherData.name}!
                        </h3>
                      ) : (
                        <h3 className="mb-0 pb-0 display-6" id="title">
                          {" "}
                          Loading school data...
                        </h3>
                      )}
                    </nav>
                  )}
                </div>
              </div>
            </div>
          </div>
          
          <div className="row">
            <div className="col-12 col-lg-12">
              {/* Inventory Start */}
              <h2 className="small-title">Quick Statistics</h2>
              <div className="mb-5">
                <div className="row g-2">
                  <div className="col-12 col-sm-6 col-lg-3">
                    <div className="card hover-scale-up cursor-pointer sh-19">
                      <div className="h-100 d-flex flex-column justify-content-between card-body align-items-center">
                        <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                          <i data-acorn-icon="eye" className="text-white" />
                        </div>
                        <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                          {teacherData ? (
                            <p> {teacherData.grade} - {teacherData.division} </p>
                          ) : (
                            <p> Loading school data...</p>
                          )}
                        </div>
                        <div className="text text-primary">Class Handling</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <div className="card hover-scale-up cursor-pointer sh-19">
                      <div className="h-100 d-flex flex-column justify-content-between card-body align-items-center">
                        <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                          <i data-acorn-icon="user" className="text-white" />
                        </div>
                        <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                          {/* {schoolData ? (
                            <p> {schoolData.students} </p>
                          ) : (
                            <p> Loading school data...</p>
                          )} */}
                          {studentCount}
                        </div>
                        <div className="text text-primary">Students</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <div className="card hover-scale-up cursor-pointer sh-19">
                      <div className="h-100 d-flex flex-column justify-content-between card-body align-items-center">
                        <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                          <i data-acorn-icon="office" className="text-white" />
                        </div>
                        <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                          {/* {schoolData ? (
                            <p> {schoolData.seats} </p>
                          ) : (
                            <p> Loading school data...</p>
                          )} */}
                          {assignmentCount}
                        </div>
                        <div className="text text-primary">Assignments</div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-3">
                    <div className="card hover-scale-up cursor-pointer sh-19">
                      <div className="h-100 d-flex flex-column justify-content-between card-body align-items-center">
                        <div className="bg-gradient-light sh-5 sw-5 rounded-xl d-flex justify-content-center align-items-center mb-2">
                          <i
                            data-acorn-icon="calendar"
                            className="text-white"
                          />
                        </div>
                        <div className="heading text-center mb-0 d-flex align-items-center lh-1">
                          {/* {schoolData ? (
                            <p>
                              {" "}
                              {format(
                                new Date(schoolData.expiry),
                                "MMMM do, yyyy"
                              )}{" "}
                            </p>
                          ) : (
                            <p> Loading school data...</p>
                          )} */}
                          0
                        </div>
                        <div className="text text-primary">Quiz Created</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Inventory End */}
              {/* assignment Start */}
              {/* assignment End */}
            </div>
          </div>
          <div className="row">
            {/* Line Chart Start */}
            <div className="col-12 col-xl-6">
              <section className="scroll-section" id="lineChartTitle">
                <h2 className="small-title">Weekly Student Activity</h2>
                <div className="card mb-5">
                  <div className="card-body">
                    <div className="sh-35">
                      <canvas id="lineChart" />
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* Line Chart End */}
            {/* Area Chart Start */}
            <div className="col-12 col-xl-6">
              <section className="scroll-section" id="areaChartTitle">
                <h2 className="small-title">Weekly Teacher Activity</h2>
                <div className="card mb-5">
                  <div className="card-body">
                    <div className="sh-35">
                      <canvas id="areaChart" />
                    </div>
                  </div>
                </div>
              </section>
            </div>
            {/* Area Chart End */}
          </div>

        </div>
      </main>

      <Tfooter />
      {/* </div> */}
    </>
  );
}

export default Tdashboard;
