import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate, useLocation  } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Shheader from "./Shheader";
import Shfooter from "./Shfooter";
import { format } from "date-fns";
import useScript from "../hooks/useScript";
import CryptoJS from "crypto-js";
import parse from "html-react-parser";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles

function Shsupportthread() {
  const [username, setUsername] = useState("");
  const [u_id, setu_id] = useState("");
  const navigate = useNavigate();
  const [schoolData, setSchoolData] = useState(null);
  //const [supporttickets, setSupporttickets] = useState([]);
  const [tickets, setTickets] = useState([]);
  const [ticketsreply, setTicketsreply] = useState([]);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [values, setValues] = useState({
    schoolid: "",
    username: "",
  });
  const [formValues, setFormValues] = useState({});
  const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const ticketid = queryParams.get('ticketid');
  
//   schoolid: "",
//   ticketid: "",
//   body: "",
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  useEffect(() => {
    const storedUsername = sessionStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
      setu_id(sessionStorage.getItem("uid"));
      fetchSchoolData(storedUsername);
      fetchSupporttickets();
      fetchSupportticketsreply();
    } else {
      navigate("/schoollogin");
    }
  }, []);

  const fetchSchoolData = (username) => {
    axios
      .get(apiUrl+"/schoolinfo", { params: { username } })
      .then((res) => {
        setSchoolData(res.data);
        sessionStorage.setItem("uid", res.data.id);
        setValues((prevValues) => ({
          ...prevValues,
          schoolid: res.data.id,
        }));
      })
      .catch((err) => {
        console.error("Error fetching school data:", err);
      });
  };

  const fetchSupporttickets = async () => {
    const uu = ticketid;
    // toast.success(uu);
    axios
      .get(apiUrl+"/schoolsupportthreadsheadbyschoolbytid", { params: { ticketid : uu } })
      .then((res) => {
        setTickets(res.data);
      })
      .catch((err) => {
        console.error("Error fetching support ticket data:", err);
      });
  };

  const fetchSupportticketsreply = async () => {
    const uuu = ticketid;
    //   toast.success(uuu);
    axios
      .get(apiUrl+"/schoolsupportthreadsreplybyschool", { params: { ticketid: uuu } })
      .then((res) => {
        setTicketsreply(res.data);
      })
      .catch((err) => {
        console.error("Error fetching support ticket data:", err);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const uu = ticketid;
    const sid = sessionStorage.getItem("uid");
    formData.append("schoolid", sid);
    formData.append("ticketid", uu);
    formData.append("bodys", values);
    //toast.success(`${uu} -- ${sid} * ${formData.get('ticketid')}`);
    axios
      .post(apiUrl+"/schoolsupportreply", formData)
      .then((response) => {
        toast.success(
          "Your reply has been submitted!"
        );
        console.log("Reply submitted successfully:", response.data);
        window.location.reload();
      })
      .catch((error) => {
        toast.error("Something went wrong. Please try after some time!");
        console.error("Error submitting ticket:", error);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };


  const handleMarkAsClosed = (e) => {
    // const location = useLocation();
    // const queryParams = new URLSearchParams(location.search);
    // const ticketid = queryParams.get('ticketid');
  
    axios
      .post(apiUrl+'/updateTicketStatus', { ticketid, status: 3 })
      .then((response) => {
        console.log('Ticket status updated successfully:', response.data);
        toast.success("Ticket has been marked as closed!");
        window.location.reload();
       // navigate("/schoolsupport");
        // Optionally, show a success message or update the UI
      })
      .catch((error) => {
        console.error('Error updating ticket status:', error);
        toast.success("Error updating ticket status:", error);
        // Optionally, show an error message
      });
    };
      

  return (
    <>
      <Helmet>
        <title>CODEX | School | Support</title>
        <meta name="description" content="View Support Tickets" />
        <meta name="keywords" content="codex, school, react" />
        {/* <script src="js/plugins/datatable.editableboxed.js" async></script> */}
      </Helmet>

      <Shheader />

      <main>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-title-container">
                {/* <h1 class="mb-0 pb-0 display-4" id="title">Teachers</h1> */}
                <nav
                  className="breadcrumb-container d-inline-block"
                  aria-label="breadcrumb"
                >
                  {schoolData ? (
                    <h3 className="mb-0 pb-0 display-6" id="title">
                      Welcome {schoolData.schoolname}!
                    </h3>
                  ) : (
                    <h3 className="mb-0 pb-0 display-6" id="title">
                      {"Loading school data..."}
                    </h3>
                  )}
                </nav>
              </div>
            </div>
          </div>

          <div className="col">
            {/* Title and Top Buttons Start */}
            <div className="page-title-container mb-3">
              <div className="row">
                {/* Title Start */}
                <div className="col mb-2">
                  <h1 className="mb-2 pb-0 display-4" id="title">
                    Support Ticket
                  </h1>
                  <div className="text-muted font-heading text-small">
                    The full conversation on the ticket can found here.
                  </div>
                </div>

                {/* Title End */}
                {/* Top Buttons Start */}
                <div className="col-12 col-md-5 d-flex align-items-center justify-content-end">
                  {/* Status Button Start */}
                  <div className="dropdown-as-select w-100 w-md-auto">
                    <button
                      className="btn btn-outline-primary w-100 w-md-auto dropdown-toggle"
                      type="button"
                      //data-bs-toggle="dropdown"
                     // aria-expanded="false"
                      onClick={handleMarkAsClosed}
                    >
                      Mark Ticket as Closed
                    </button>
                    {/*
              <div className="dropdown-menu dropdown-menu-end">
                <a className="dropdown-item" href="#">
                  Status: Solved
                </a>
                <a className="dropdown-item active" href="#">
                  Status: Active
                </a>
              </div> */}
                  </div>
                  {/* Status Button End */}
                </div>
                {/* Top Buttons End */}
              </div>
            </div>
            {/* Title and Top Buttons End */}
            <div className="row">
              <div className="col-12 col-xxl-8 mb-5 mb-xxl-0">
                {/* {tickets.map(ticket => ( */}
                {tickets.map((tickett) => (
                  <div key={tickett.id}>
                    {/* Ticket Details Start */}
                    <h1 className="medium-title">INITIAL TICKET DETAILS</h1>
                    {/* <strong>{ticket.title}</strong><br /> */}
                    <div className="card mb-2">
                      <div className="card-body">
                        <div className="mb-4 pb-4 border-bottom border-separator-light">
                          <div className="row g-0 sh-sm-5 h-auto">
                            <div className="col">
                              <div className="row h-100 g-2">
                                <div className="col h-sm-100 d-flex flex-column justify-content-sm-center mb-1 mb-sm-0">
                                  <h2>TITLE : {tickett.title} </h2>
                                  {/* <div className="text-small text-muted">
                          lisajackson@gmail.com
                        </div> */}
                                </div>
                                <div className="col-12 order-3 order-sm-0 col-sm-auto sw-sm-11 sw-sm-11 lh-1-5 text-small text-muted text-sm-end d-flex flex-column justify-content-sm-center">
                                  {format(
                                    new Date(tickett.created),
                                    "MMMM do, yyyy"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="mt-4">
                              {parse(tickett.description)}
                            </div>

                            {/* <div className="mt-4 d-flex flex-row flex-wrap">
                    <div className="sw-30 me-2 mb-2">
                      <div className="row g-0 rounded-md sh-8 border">
                        <div className="col-auto">
                          <div className="sw-10 d-flex justify-content-center align-items-center h-100">
                            <i
                              data-acorn-icon="file-text"
                              className="preview-icon text-primary"
                            />
                          </div>
                        </div>
                        <div className="col rounded-md-end d-flex flex-column justify-content-center pe-3">
                          <div className="d-flex justify-content-between">
                            <p className="mb-0 clamp-line" data-line={1}>
                            {ticket.supportingdoc}
                            </p>
                       
                            <a
                              href="/${ticket.supportingdoc}"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Download"
                              data-delay='{"show":"1000", "hide":"0"}'
                            >
                              <i
                                data-acorn-icon="download"
                                data-acorn-size={17}
                                className="text-alternate"
                              />
                            </a>
                            
                          </div>
                          <div className="text-small text-primary">Attached File</div>
                        </div>
                      </div>
                    </div>
                  </div> */}
                          </div>
                        </div>

                        {/* <div className="mb-4 pb-4 border-bottom border-separator-light">
                <div className="row g-0 sh-sm-5 h-auto">
                  <div className="col-auto">
                    <img
                      src="img/profile/profile-8.webp"
                      className="card-img rounded-xl sh-5 sw-5"
                      alt="thumb"
                    />
                  </div>
                  <div className="col ps-3">
                    <div className="row h-100 g-2">
                      <div className="col h-sm-100 d-flex flex-column justify-content-sm-center mb-1 mb-sm-0">
                        <div>Blaine Doe</div>
                        <div className="text-small text-muted">
                          blainecottrell@msn.com
                        </div>
                      </div>
                      <div className="col-12 order-3 order-sm-0 col-sm-auto sw-sm-11 lh-1-5 text-small text-muted text-sm-end d-flex flex-column justify-content-sm-center">
                        12 Feb 2021 21:40
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="mt-4">
                    <p>Hi Lisa,</p>
                    <p>
                      Jelly-o jelly oat cake cheesecake halvah. Cupcake sweet
                      roll donut. Sesame snaps lollipop macaroon.
                      <a href="#">Icing tiramisu</a>
                      oat cake chocolate cake marzipan pudding danish gummies.
                      Dragée liquorice jelly beans jelly jelly sesame snaps
                      brownie. Pastry cake tart apple pie bear claw sweet. Apple
                      pie macaroon sesame snaps cotton candy jelly
                      <u>pudding lollipop caramels</u>
                      marshmallow. Powder halvah dessert ice cream. Carrot cake
                      gingerbread chocolate cake tootsie roll. Oat cake jujubes
                      jelly-o jelly chupa chups lollipop jelly wafer soufflé.
                    </p>
                    <p className="mb-0">Blaine Doe</p>
                  </div>
                </div>
              </div>
              <div>
                <div className="row g-0 sh-sm-5 h-auto mb-4">
                  <div className="col-auto">
                    <img
                      src="img/profile/profile-9.webp"
                      className="card-img rounded-xl sh-5 sw-5"
                      alt="thumb"
                    />
                  </div>
                  <div className="col ps-3">
                    <div className="row h-100 g-2">
                      <div className="col h-sm-100 d-flex flex-column justify-content-sm-center mb-1 mb-sm-0">
                        <div>Blaine Doe</div>
                        <div className="text-small text-muted">
                          lisajackson@gmail.com
                        </div>
                      </div>
                      <div className="col-12 order-3 order-sm-0 col-sm-auto sw-sm-11 sw-sm-11 lh-1-5 text-small text-muted text-sm-end d-flex flex-column justify-content-sm-center">
                        13 Feb 2021 09:20
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <p>Hi,</p>
                  <p>
                    Cake chocolate bar biscuit sweet roll liquorice jelly
                    jujubes. Gingerbread icing macaroon bear claw jelly toffee.
                    Chocolate cake marshmallow muffin wafer. Pastry cake tart
                    apple pie bear claw sweet. Apple pie macaroon sesame snaps
                    cotton candy jelly
                    <u>pudding lollipop caramels</u>
                    marshmallow.
                  </p>
                  <p>
                    Powder halvah dessert ice cream. Carrot cake gingerbread
                    chocolate cake tootsie roll. Oat cake jujubes jelly-o jelly
                    chupa chups lollipop jelly wafer soufflé. Chocolate cake
                    marshmallow muffin wafer. Pastry cake tart apple pie bear
                    claw sweet. Apple pie macaroon sesame snaps cotton candy
                    jelly.
                  </p>
                  <p className="mb-0">Lisa</p>
                 
                  <div className="mt-4 d-flex flex-row flex-wrap">
                    <div className="sw-30 me-2 mb-2">
                      <div className="row g-0 rounded-md sh-8 border">
                        <div className="col-auto">
                          <div className="sw-10 d-flex justify-content-center align-items-center h-100">
                            <i
                              data-acorn-icon="file-text"
                              className="preview-icon text-primary"
                            />
                          </div>
                        </div>
                        <div className="col rounded-md-end d-flex flex-column justify-content-center pe-3">
                          <div className="d-flex justify-content-between">
                            <p className="mb-0 clamp-line" data-line={1}>
                              logs.txt
                            </p>
                            <a
                              href="#"
                              data-bs-toggle="tooltip"
                              data-bs-placement="top"
                              title="Download"
                              data-delay='{"show":"1000", "hide":"0"}'
                            >
                              <i
                                data-acorn-icon="download"
                                data-acorn-size={17}
                                className="text-alternate"
                              />
                            </a>
                          </div>
                          <div className="text-small text-primary">26 KB</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div> */}
                      </div>
                    </div>
                    {/* Ticket Details End */}
                    {/* ))} */}
                  </div>
                ))}

                {/* thread */}

                <h1 className="medium-title">TICKET RESPONSE</h1>

                {ticketsreply.map((tickettr) => (
                  <div key={tickettr.id}>
                    {/* Ticket Details Start */}

                    {/* <strong>{ticket.title}</strong><br /> */}
                    <div className="card mb-2">
                      <div className="card-body">
                        <div className="mb-4 pb-4 border-bottom border-separator-light">
                          <div className="row g-0 sh-sm-5 h-auto">
                            <div className="col">
                              <div className="row h-100 g-2">
                                <div className="col h-sm-100 d-flex flex-column justify-content-sm-center mb-1 mb-sm-0">
                                  <h3>
                                    Replied by{" "}
                                    {tickettr.userrole === "admin"
                                      ? "Support"
                                      : tickettr.userrole === "school"
                                      ? "You"
                                      : "Unknown"}
                                  </h3>
                                </div>
                                <div className="col-12 order-3 order-sm-0 col-sm-auto sw-sm-11 sw-sm-11 lh-1-5 text-small text-muted text-sm-end d-flex flex-column justify-content-sm-center">
                                  {format(
                                    new Date(tickettr.created),
                                    "MMMM do, yyyy"
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div>
                            <div className="mt-4">{parse(tickettr.body)}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Ticket Details End */}
                    {/* ))} */}
                  </div>
                ))}

                {/* thread */}

                <h1 className="medium-title">REPLY</h1>

                {/* Ticket Answer Start */}
                <div className="card">
                  <form
                    className="card mb-5 tooltip-end-top"
                    noValidate=""
                    onSubmit={handleSubmit}
                  >
                    <div className="card-body">
                      <ReactQuill
                        value={values}
                        placeholder="Keep typing here and the textarea will grow..."
                        height="500px !important"
                        rows={10}
                        onChange={setValues}
                        modules={modules}
                        formats={formats}
                      />
                      <p></p>
                      <button
                        className="btn btn-icon btn-icon-end btn-outline-primary"
                        type="submit"
                      >
                        <span>Reply now</span>
                        <i data-acorn-icon="send" />
                      </button>
                    </div>
                  </form>
                </div>
                {/* Ticket Answer End */}
              </div>

              {/* <button
                      type="button"
                      className="btn btn-outline-primary btn-icon btn-icon-only me-1"
                    >
                      <i data-acorn-icon="attachment" />
                    </button> */}

              <div className="col-12 col-xxl-4 mb-n5">
                {tickets.map((ticket) => (
                  <div key={ticket.id}>
                    {/* Activity Start */}
                    <h2 className="small-title">Ticket Snapshot</h2>
                    <div className="card mb-5">
                      <div className="card-body">
                        <div className="row g-0">
                          <div className="col-auto sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-4">
                            <div className="w-100 d-flex sh-1" />
                            <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                              <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                            </div>
                            <div className="w-100 d-flex h-100 justify-content-center position-relative">
                              <div className="line-w-1 bg-separator h-100 position-absolute" />
                            </div>
                          </div>
                          <div className="col mb-4">
                            <div className="h-100">
                              <div className="d-flex flex-column justify-content-start">
                                <div className="d-flex flex-column">
                                  <a
                                    href="#"
                                    className="heading stretched-link"
                                  >
                                    Ticket Created
                                  </a>
                                  <div className="text-alternate">
                                    {" "}
                                    {format(
                                      new Date(ticket.created),
                                      "MMMM do, yyyy"
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row g-0">
                          <div className="col-auto sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-4">
                            <div className="w-100 d-flex sh-1 position-relative justify-content-center">
                              <div className="line-w-1 bg-separator h-100 position-absolute" />
                            </div>
                            <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                              <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                            </div>
                            <div className="w-100 d-flex h-100 justify-content-center position-relative">
                              <div className="line-w-1 bg-separator h-100 position-absolute" />
                            </div>
                          </div>
                          <div className="col mb-4">
                            <div className="h-100">
                              <div className="d-flex flex-column justify-content-start">
                                <div className="d-flex flex-column">
                                  <a
                                    href="#"
                                    className="heading stretched-link"
                                  >
                                    Ticket ID
                                  </a>
                                  <div className="text-alternate">
                                    {ticket.ticketid}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row g-0">
                          <div className="col-auto sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-4">
                            <div className="w-100 d-flex sh-1 position-relative justify-content-center">
                              <div className="line-w-1 bg-separator h-100 position-absolute" />
                            </div>
                            <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                              <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                            </div>
                            <div className="w-100 d-flex h-100 justify-content-center position-relative">
                              <div className="line-w-1 bg-separator h-100 position-absolute" />
                            </div>
                          </div>
                          <div className="col mb-4">
                            <div className="h-100">
                              <div className="d-flex flex-column justify-content-start">
                                <div className="d-flex flex-column">
                                  <a
                                    href="#"
                                    className="heading stretched-link"
                                  >
                                    Category
                                  </a>
                                  <div className="text-alternate">
                                    {ticket.categoryname}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row g-0">
                          <div className="col-auto sw-1 d-flex flex-column justify-content-center align-items-center position-relative me-4">
                            <div className="w-100 d-flex sh-1 position-relative justify-content-center">
                              <div className="line-w-1 bg-separator h-100 position-absolute" />
                            </div>
                            <div className="rounded-xl shadow d-flex flex-shrink-0 justify-content-center align-items-center">
                              <div className="bg-gradient-light sw-1 sh-1 rounded-xl position-relative" />
                            </div>
                            <div className="w-100 d-flex h-100 justify-content-center position-relative" />
                          </div>
                          <div className="col">
                            <div className="h-100">
                              <div className="d-flex flex-column justify-content-start">
                                <div className="d-flex flex-column">
                                  <a
                                    href="#"
                                    className="heading stretched-link pt-0"
                                  >
                                    Status
                                  </a>
                                  <div className="text-alternate">
                                    {ticket.statusname}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* Activity End */}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>

      <Shfooter />
      <ToastContainer />
    </>
  );
}

export default Shsupportthread;
