import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Theader from "./Theader";
import Tfooter from "./Tfooter";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // Import Quill styles
import { ClipLoader } from "react-spinners"; // Import the spinner
import moment from 'moment';

function Tassignmentsadd() {
  const [username, setUsername] = useState("");
  const apiUrl = process.env.REACT_APP_API_URL;
  const navigate = useNavigate();
  const [teacherData, setTeacherData] = useState(null);
  const [schoolData, setSchoolData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [grades, setGrades] = useState([]);
  const [divisions, setDivisions] = useState([]);
  const [assignmenttypes, setAssignmenttypes] = useState([]);
  const [values, setValues] = useState({
    title: '',
    schoolid: '',
    teacherid:'',
    grade: '',
    division: '',
    assignmenttypes: '',
    assignmentdetails: '',
    deadline: '',
    featuredimage: null
  });

  useEffect(() => {
    const storedUsername = sessionStorage.getItem("tusername");
    const storedSchoolid = sessionStorage.getItem("schoolid");
    const storedTeacherid = sessionStorage.getItem("tuid");

    fetchGrades();
    fetchDivisions();
    fetchAssignmenttypes();

    if (storedUsername) {
      setUsername(storedUsername);
      setValues((prevValues) => ({
        ...prevValues,
        schoolid: storedSchoolid,
        teacherid: storedTeacherid,
    }));
      fetchSchoolData(storedSchoolid);
      fetchTeacherData(storedTeacherid);
    } else {
      navigate("/teacherlogin");
    }
    //}
  }, [navigate]);

  const fetchSchoolData = async (id) => {
    setLoading(true);
    try {
      const res = await axios.get(apiUrl + "/schoolinfobyteacherid", {
        params: { id },
      });
      setSchoolData(res.data);
    } catch (err) {
      console.error("Error fetching school data:", err);
    } finally {
      setLoading(false);
    }
  };

  const fetchTeacherData = async (id) => {
    setLoading(true);
    try {
      const res = await axios.get(apiUrl + "/teacherinfobyteacherid", {
        params: { id },
      });
      setTeacherData(res.data);
    } catch (err) {
      console.error("Error fetching teacher data:", err);
    } finally {
      setLoading(false);
    }
  };

  const fetchGrades = async () => {
    try {
      const res = await axios.get("/grades");
      await setGrades(res.data);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const fetchDivisions = async () => {
    try {
      const res = await axios.get(apiUrl + "/divisions");
      await setDivisions(res.data);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const fetchAssignmenttypes = async () => {
    try {
      const res = await axios.get(apiUrl + "/assignmenttypes");
      await setAssignmenttypes(res.data);
    } catch (err) {
      console.error("Error fetching data:", err);
    }
  };

  const handleChange = (e) => {
    const { name,value } = e.target;
    //const selectedText = e.target.options[e.target.selectedIndex].text;
    setValues({
      ...values,
      [name]: value,
    });
  };

  const handleChanges = (e) => {
    const { name } = e.target;
    const selectedText = e.target.options[e.target.selectedIndex].text;
    setValues({
      ...values,
      [name]: selectedText,
    });
  };

  const handleChangesx = (e) => {
    const { name } = e.target;
    const selectedText = e.target.options[e.target.selectedIndex].value;
    setValues({
      ...values,
      [name]: selectedText,
    });
  };

  const handleQuillChange = (value) => {
    setValues({
        ...values,
        assignmentdetails: value
    });
};

const handleFileChange = (e) => {
    setValues({
        ...values,
        featuredimage: e.target.files[0]
    });
};



const handleSubmit = async (e) => {
    e.preventDefault();
    //const data = new values();
    // data.append('grade', values.grade);
    // data.append('division', values.division);
    // data.append('assignmenttype', values.assignmenttype);
    // data.append('assignmentdetails', values.assignmentdetails);
    // data.append('deadline', values.deadline);
    // data.append('featuredimage', values.featuredimage);
    const formattedDeadline = new Date(values.deadline).toISOString().slice(0, 19).replace('T', ' ');
    //moment(values.deadline).format('YYYY-MM-DD HH:mm:ss');
    const formData = new FormData();
        // formData.append('deadline', formattedDeadline);
        formData.append('title', values.title);
        formData.append('grade', values.grade);
        formData.append('division', values.division);
        formData.append('assignmenttypes', values.assignmenttypes);
        formData.append('assignmentdetails', values.assignmentdetails);
        formData.append('deadline', formattedDeadline);
        formData.append('featuredimage', values.featuredimage);
        formData.append('schoolid', values.schoolid);
        formData.append('teacherid', values.teacherid);
       // toast.success(values.assignmentdetails);

    try {
        const response = await axios.post(apiUrl+"/add_assignment_byteacher", formData, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
        if (response.data.success) {
            toast.success('Assignment added successfully');
        } else {
            toast.error('Failed to add assignment');
        }
    } catch (error) {
        console.error('Error adding assignment:', error);
        toast.error('An error occurred while adding the assignment');
    }
};




  return (
    <>
      <Helmet>
        <title>CODEX | Teacher | Add Assignment</title>
        <meta name="description" content="Teacher Add Assignment Page." />
        <meta name="keywords" content="codex, Teacher, react" />
      </Helmet>

      <Theader />

      <main>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-title-container">
                <nav
                  className="breadcrumb-container d-inline-block"
                  aria-label="breadcrumb"
                >
                  {teacherData ? (
                        <h3 className="mb-0 pb-0 display-6" id="title">
                          Welcome {teacherData.name}!
                        </h3>
                      ) : (
                        <h3 className="mb-0 pb-0 display-6" id="title">
                          {" "}
                          Loading school data...
                        </h3>
                      )}
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {/* Title Start */}
              {/* Title End */}
              {/* Content Start */}
              <div className="row">
                <div className="col">
                  {/* Title and Top Buttons Start */}
                  <div className="page-title-container">
                    <div className="row">
                      {/* Title Start */}
                      <div className="col-12 col-md-7">
                        <h1 className="mb-0 pb-0 display-4" id="title">
                          Add Assignment
                        </h1>
                        <nav
                          className="breadcrumb-container d-inline-block"
                          aria-label="breadcrumb"
                        >
                          <ul className="breadcrumb pt-0">
                            <li className="breadcrumb-item">
                              <a href="#">Home</a>
                            </li>
                            <li className="breadcrumb-item">
                              <a href="#">Add Assignment</a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </div>


                  <form
                    className="card mb-5 tooltip-end-top"
                    //   id="loginForm"
                    noValidate=""
                    onSubmit={handleSubmit}
                  >

                    <input
                      type="hidden"
                      name="schoolid"
                      //value={values.schoolid}
                      value={values.schoolid}
                    />

                    <input
                      type="hidden"
                      name="teacherid"
                      //value={values.schoolid}
                      value={values.teacherid}
                    />

                  <div>
                    <h2 className="small-title">General Info</h2>
                    <div className="card mb-5">
                      <div className="card-body">
                        <div className="col-sm-6">
                          <label className="" htmlFor="specificSizeSelect">
                            Grade
                          </label>
                          <select
                              name="grade"
                              onChange={handleChanges}
                              className="w-100"
                            >
                              <option value="">Select a grade</option>
                              {grades.map((grad) => (
                                <option key={grad.id} value={grad.id}>
                                  {grad.gradename}
                                </option>
                              ))}
                            </select>
                        </div>
                        <div className="col-sm-6">
                          <label className="" htmlFor="specificSizeSelect">
                            Division
                          </label>
                          <select
                              // id="selectTemplating"
                              name="division"
                              // value={values.division}
                              onChange={handleChanges}
                              className="w-100"
                            >
                              <option value="">Select a division</option>
                              {divisions.map((divi) => (
                                <option key={divi.id} value={divi.id}>
                                  {divi.divisionname}
                                </option>
                              ))}
                            </select>
                        </div>
                      </div>
                    </div>
                    <section className="scroll-section" id="horizontalForm">
                      <h2 className="small-title">Answer Type</h2>
                      <div className="card mb-5">
                        <div className="card-body">
                          
                            <div className="row mb-3">
                              <div className="col-sm-10">
                        
                              <select
                              // id="selectTemplating"
                              name="assignmenttypes"
                               value={values.assignmenttypes}
                              onChange={handleChangesx}
                              className="w-100"
                            >
                              <option value="">Select assignment types</option>
                              {assignmenttypes.map((ass) => (
                                <option key={ass.id} value={ass.id}>
                                  {ass.typename}
                                </option>
                              ))}
                            </select>

                              </div>
                            </div>
                      
                        </div>
                      </div>
                    </section>
                    <section className="scroll-section" id="quillStandart">
                     
                     
                    <h2 className="small-title">Assignment Title</h2>
                    <div className="card mb-5">
                        <div className="card-body editor-container">
                          {/* <div className="html-editor sh-19" id="quillEditor" /> */}
                           <input
                          className="form-control"
                          placeholder="Title..."
                          name="title"
                          value={values.title}
                          onChange={handleChange}
                          required
                        />

                        </div>
                      </div>
                     
                     
                     
                     
                      <h2 className="small-title">Assignment Question?</h2>
                      <div className="card mb-5">
                        <div className="card-body editor-container">
                          {/* <div className="html-editor sh-19" id="quillEditor" /> */}
                          {/* <ReactQuill theme="snow"  name="assignmentdetails"
                                                    value={values.assignmentdetails}
                                                    onChange={handleQuillChange}                                                     
                                                    className="html-editor sh-19"
                                                    id="quillEditor"
                                                /> */}
                                                  <textarea
                name="assignmentdetails"
                onChange={handleChange}
                placeholder="Assignment Details"
               className="form-control"
                rows="4"
                cols="50"
            />

                        </div>
                      </div>





                    </section>
                    <section className="scroll-section" id="autoSizing">
                      <h2 className="small-title">Other info</h2>
                      <div className="card mb-5">
                        <div className="card-body">
                          <div className="col-sm-6">
                            <label className="">Featured Image</label>
                            <div className="input-group">
                            <input type="file" className="form-control" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload" onChange={handleFileChange} />
                              {/* <button
                                className="btn btn-outline-secondary"
                                type="button"
                                id="inputGroupFileAddon04"
                              >
                                Upload
                              </button> */}
                            </div>
                          </div>
                          <br />
                          <div className="col-sm-6">
                            <label className="">Deadline</label>
                            <div className="filled">
                              <i data-acorn-icon="calendar" />
                              {/* id="datePickerFilled" */}
                              <input type="date" className="form-control" placeholder="Date"  name="deadline" onChange={handleChange} />
                            </div>
                          </div>
                          <br />
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </div>
                    </section>
                    {/* Help Text End */}
                  </div>


                   </form>


                </div>
              </div>
              {/* Content End */}
            </div>
          </div>
        </div>
      </main>

      <Tfooter />
      <ToastContainer />
      {/* </div> */}
    </>
  );
}

export default Tassignmentsadd;
