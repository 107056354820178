import React from 'react'
import { Link, useNavigate   } from "react-router-dom";

function Theader() {

    const navigate = useNavigate();

    const handleLogout = (e) => {
      e.preventDefault();
      // Clear user data from local storage or session storage
      localStorage.removeItem('userToken');
      sessionStorage.removeItem('tusername');
      sessionStorage.removeItem('tuid');
      //sessionStorage.removeItem('hasRefreshedsupport');
      sessionStorage.removeItem('hasRefreshedd');
      
      // Redirect to login page
      navigate('/teacherlogin');
    };

    

  return (
    
    <>
     
    <div id="nav" className="nav-container d-flex">
  <div className="nav-content d-flex">
    <div className="logo position-relative">
      <a href="/teacherdashboard">
        <img src="img/logo/logotrans.png" alt="logo" />
      </a>
    </div>

    {/* Language Switch Start */}
    {/* <div className="language-switch-container">
      <button
        className="btn btn-empty language-button dropdown-toggle"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        EN
      </button>
      <div className="dropdown-menu">
        <a href="#" className="dropdown-item active">
          EN
        </a>
        <a href="#" className="dropdown-item">
          AR
        </a>
      </div>
    </div> */}
    {/* Language Switch End */}
    {/* User Menu Start */}
    <div className="user-container d-flex">
      <a
        href="#"
        className="d-flex user position-relative"
        data-bs-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {/* <img className="profile" alt="profile" src="img/profile/edu.jpg" /> */}
        <div className="name">Administration</div>
      </a>
      <div className="dropdown-menu dropdown-menu-end user-menu wide">
        <div className="row mb-3 ms-0 me-0">
          <div className="col-12 ps-1 mb-2">
            <div className="text-big text-primary">QUICK SETTINGS MENU</div>
          </div>
          <div className="col-6 ps-1 pe-1">
            <ul className="list-unstyled">
              <li>
                <a href="#">My Profile</a>
              </li>
            </ul>
          </div>
          <div className="col-12 pe-1 ps-1">
            <ul className="list-unstyled">
              <li>
                <a href="#">My Preferences</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row mb-1 ms-0 me-0">
          <div className="col-12 p-1 mb-3 pt-3">
            <div className="separator-light" />
          </div>
          <div className="col-12 ps-1 pe-1">
            <ul className="list-unstyled">
              <li>
                {/* <a href="codex.school.faq.html">
                  <i
                    data-acorn-icon="file-text"
                    className="me-2"
                    data-acorn-size={17}
                  />
                  <span className="align-middle">FAQ</span>
                </a> */}
                 <button  className="btn btn-primary" onClick={handleLogout}> Logout </button>
              </li>
            </ul>
          </div>
          <div className="col-6 pe-1 ps-1">
            <ul className="list-unstyled">
              <li>
                {/* <a href="">
                  <i
                    data-acorn-icon="logout"
                    className="me-2"
                    data-acorn-size={17}
                    onClick={handleLogout}
                  />
                  <span className="align-middle">Logout</span>
                </a> */}
                
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    {/* User Menu End */}
    {/* Icons Menu Start */}
    <ul className="list-unstyled list-inline text-center menu-icons">
      {/* <li className="list-inline-item">
        <a href="#" data-bs-toggle="modal" data-bs-target="#searchPagesModal">
          <i data-acorn-icon="search" data-acorn-size={18} />
        </a>
      </li> */}
      {/* <li className="list-inline-item">
        <a href="#" id="pinButton" className="pin-button">
          <i data-acorn-icon="lock-on" className="unpin" data-acorn-size={18} />
          <i data-acorn-icon="lock-off" className="pin" data-acorn-size={18} />
        </a>
      </li> */}
      <li className="list-inline-item">
        <a href="#" id="colorButton">
          <i
            data-acorn-icon="light-on"
            className="light"
            data-acorn-size={18}
          />
          <i
            data-acorn-icon="light-off"
            className="dark"
            data-acorn-size={18}
          />
        </a>
      </li>
      <li className="list-inline-item">
        <a
          href="#"
          data-bs-toggle="dropdown"
          data-bs-target="#notifications"
          aria-haspopup="true"
          aria-expanded="false"
          className="notification-button"
        >
          <div className="position-relative d-inline-flex">
            <i data-acorn-icon="bell" data-acorn-size={18} />
            <span className="position-absolute notification-dot rounded-xl" />
          </div>
        </a>
        <div
          className="dropdown-menu dropdown-menu-end wide notification-dropdown scroll-out"
          id="notifications"
        >
          <div className="scroll">
            <ul className="list-unstyled border-last-none">
              {/* <li className="mb-3 pb-3 border-bottom border-separator-light d-flex">
                <img
                  src="img/profile/profile-1.webp"
                  className="me-3 sw-4 sh-4 rounded-xl align-self-center"
                  alt="..."
                />
                <div className="align-self-center">
                  <a href="#">Teacher Loisse Kaycee just sent a new comment!</a>
                </div>
              </li> */}
              <li className="mb-3 pb-3 border-bottom border-separator-light d-flex">
                {/* <img
                  src="img/profile/profile-2.webp"
                  className="me-3 sw-4 sh-4 rounded-xl align-self-center"
                  alt="..."
                /> */}
                <div className="align-self-center">
                  <a href="#">No new notifications for you!</a>
                </div>
              </li>

            </ul>
          </div>
        </div>
      </li>
    </ul>
    {/* Icons Menu End */}
    {/* Menu Start */}
    <div className="menu-container flex-grow-1">
      <ul id="menu" className="menu">
        <li>
          {/* <a
            href="codex.school.dashboard.html"
            data-href="codex.school.dashboard.html"
          >
            <i data-acorn-icon="home" className="icon" data-acorn-size={18} />
            <span className="label">Dashboards</span>
          </a> */}
          <Link to="/teacherdashboard" className="">
          <i data-acorn-icon="home" className="icon" data-acorn-size={18} />
          <span className="label">Dashboard</span></Link>
        </li>
        <li>
                <a href="#std" data-href="#">
                  <i data-acorn-icon="user" class="icon" data-acorn-size="18"></i>
                  <span class="label">Students</span>
                </a>
                <ul id="std">
                  <li>
                    <a href="/teacheraddstudent">
                      <span class="label">Add Student</span>
                    </a>
                  </li>
                  {/* <li>
                    <a href="/teacherbulkupload">
                      <span class="label">Bulk Upload Students</span>
                    </a>
                  </li> */}
                  <li>
                    <a href="/teacherviewstudents">
                      <span class="label">View Students</span>
                    </a>
                  </li>
                  
                </ul>
              </li>          
              <li class="mega">
                <a href="/teachercourses" data-href="/teachercourses">
                  <i data-acorn-icon="check-square" class="icon" data-acorn-size="18"></i>
                  <span class="label">Courses</span>
                </a>
              </li>
              <li class="mega">
                <a href="/teacherquiz" data-href="/teacherquiz">
                  <i data-acorn-icon="controller" class="icon" data-acorn-size="18"></i>
                  <span class="label">Quiz</span>
                </a>
              </li>
              <li>
                <a href="/teacherassessment" data-href="/teacherassessment">
                  <i data-acorn-icon="help" class="icon" data-acorn-size="18"></i>
                  <span class="label">Assessment</span>
                </a>
              </li>
              <li>
                <a href="#asgg" data-href="#">
                  <i data-acorn-icon="grid-5" class="icon" data-acorn-size="18"></i>
                  <span class="label">Assignment</span>
                </a>
                <ul id="asgg">
                  <li>
                    <a href="/teacheraddassignment">
                      <span class="label">Add Assignment</span>
                    </a>
                  </li>
                  {/* <li>
                    <a href="/teacherviewassignments">
                      <span class="label">View Assigments</span>
                    </a>
                  </li> */}
                  {/* <li>
                    <a href="codex.teacher.reviewsubmissions.html">
                      <span class="label">Review Submissions</span>
                    </a>
                  </li> */}
                  
                </ul>
              </li>
      </ul>
    </div>
    {/* Menu End */}
    {/* Mobile Buttons Start */}
    <div className="mobile-buttons-container">
      {/* Scrollspy Mobile Button Start */}
      <a
        href="#"
        id="scrollSpyButton"
        className="spy-button"
        data-bs-toggle="dropdown"
      >
        <i data-acorn-icon="menu-dropdown" />
      </a>
      {/* Scrollspy Mobile Button End */}
      {/* Scrollspy Mobile Dropdown Start */}
      <div className="dropdown-menu dropdown-menu-end" id="scrollSpyDropdown" />
      {/* Scrollspy Mobile Dropdown End */}
      {/* Menu Button Start */}
      <a href="#" id="mobileMenuButton" className="menu-button">
        <i data-acorn-icon="menu" />
      </a>
      {/* Menu Button End */}
    </div>
    {/* Mobile Buttons End */}
  </div>
  <div className="nav-shadow" />
</div>

    
    </>
  )
}

export default Theader