import React from 'react'
import {BrowserRouter , Routes, Route } from 'react-router-dom';
import Cdxindex from './codexmainsite/Cdxindex';
import Shlogin from './school/Shlogin';
import Shdashboard from './school/Shdashboard';
import Shaddteacher from './school/Shaddteacher';
import Shviewteachers from './school/Shviewteachers';
import Shaddstudent from './school/Shaddstudent';
import Shviewstudents from './school/Shviewstudents';
import Shrequestseats  from './school/Shrequestseats';
import Shsupportmain from './school/Shsupportmain';
import Shnewticket from './school/Shnewticket';
import Shsupportthread from './school/Shsupportthread';
import Shcompetitions from './school/Shcompetitions';
import Shprofile from './school/Shprofile';
import Shpreferences from './school/Shpreferences';
import Plogin from './parent/Plogin';
import Pdashboard from './parent/Pdashboard';
import Pcertificate from './parent/Pcertificate';
import Pleaderboard from './parent/Pleaderboard';
import Passignment from './parent/Passignment';
import Pcompetitions from './parent/Pcompetitions';
import Tester from './codexmainsite/Tester';
import Tlogin from './teacher/Tlogin';
import Tdashboard from './teacher/Tdashboard';
import Taddstudent from './teacher/Taddstudent';
import Tbulkupload from './teacher/Tbulkupload';
import Tviewstudents from './teacher/Tviewstudents';
import Tcourses from './teacher/Tcourses';
import Tquiz from './teacher/Tquiz';
import Tassessment from './teacher/Tassessment';
import Tassignmentsadd from './teacher/Tassignmentsadd';
import Tviewassignments from './teacher/Tviewassignments';

const App = () => {
  return (
    
    <BrowserRouter>
    <Routes>

      {/* Main site */}
      <Route path ='/' element={<Cdxindex/>}/>
      <Route path ='/test' element={<Tester/>}/>



      {/* School */}
      <Route path ='/schoollogin' element={<Shlogin/>}/>
      <Route path ='/schooldashboard' element={<Shdashboard/>}/>
      <Route path ='/schooladdteacher' element={<Shaddteacher/>}/>
      <Route path ='/schoolviewteacher' element={<Shviewteachers/>}/>
      <Route path ='/schooladdstudent' element={<Shaddstudent/>}/>
      <Route path ='/schoolviewstudents' element={<Shviewstudents/>}/>
      <Route path ='/schoolrequestseats' element={<Shrequestseats/>}/>
      <Route path ='/schoolsupport' element={<Shsupportmain/>}/>
      <Route path ='/schoolnewticket' element={<Shnewticket/>}/>
      <Route path ='/schoolshowthread' element={<Shsupportthread/>}/>
      <Route path ='/schoolcompetitions' element={<Shcompetitions/>}/>
      <Route path ='/schoolprofile' element={<Shprofile/>}/>
      <Route path ='/schoolpreferences' element={<Shpreferences/>}/>




      {/* Parent */}
      <Route path ='/parentlogin' element={<Plogin/>}/>
      <Route path ='/parentdashboard' element={<Pdashboard/>}/>
      <Route path ='/parentcertificate' element={<Pcertificate/>}/>
      <Route path ='/parentleaderboard' element={<Pleaderboard/>}/>
      <Route path ='/parentassignment' element={<Passignment/>}/>
      <Route path ='/parentcompetitions' element={<Pcompetitions/>}/>


      {/* Teacher */}
      <Route path ='/teacherlogin' element={<Tlogin/>}/>
      <Route path ='/teacherdashboard' element={<Tdashboard/>}/>
      <Route path ='/teacheraddstudent' element={<Taddstudent/>}/>
      <Route path ='/teacherbulkupload' element={<Tbulkupload/>}/>
      <Route path ='/teacherviewstudents' element={<Tviewstudents/>}/>
      <Route path ='/teachercourses' element={<Tcourses/>}/>
      <Route path ='/teacherquiz' element={<Tquiz/>}/>
      <Route path ='/teacherassessment' element={<Tassessment/>}/>
      <Route path ='/teacheraddassignment' element={<Tassignmentsadd/>}/>
      <Route path ='/teacherviewassignments' element={<Tviewassignments/>}/>

    </Routes>
    </BrowserRouter>



  );
};

export default App