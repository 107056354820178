import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Cdxindex() {
  return (
    <>
    <Helmet>
      <title>CODEX | MAIN PAGE</title>
      <meta name="description" content="Main Page." />
      <meta name="keywords" content="codex, react" />
    </Helmet>

    {/* Background Start */}
    <div className="fixed-background-admin" />
    {/* Background End */}
    <div className="container-fluid p-0 h-100 position-relative">
      <div className="row g-0 h-100">
        {/* Left Side Start */}
        <div className="offset-0 col-12 d-none d-lg-flex offset-md-1 col-lg h-lg-100">
          <div className="min-h-100 d-flex align-items-center">
            <div className="w-100 w-lg-75 w-xxl-50"></div>
          </div>
        </div>
        {/* Left Side End */}
        {/* Right Side Start */}
        <div className="col-12 col-lg-auto h-100 pb-4 px-4 pt-0 p-lg-0">
          <div className="sw-lg-70 min-h-100 bg-foreground d-flex justify-content-center align-items-center shadow-deep py-5 full-page-content-right-border">
            <div className="sw-lg-50 px-5">
              <div className="sh-11">
                <a href="index.html">
                  <img src="img/logo/logo.png" width="200px" />
                  {/* <div class="logo-default"></div> */}
                </a>
              </div>
              <div className="mb-5">
                <h2 className="cta-1 mb-0 text-primary">DEMO LINKS</h2>
              </div>

              <hr></hr>
              <div className="mb-5">
              <p className=" text-primary">SCHOOL'S LINK</p>
                <p className="h6">Link : <a target="_blank" href="/schoollogin">School Login</a></p>
                <p className="h6">Username : dbs</p>
                <p className="h6">Password : dbs123</p>
              </div>

              <hr></hr>
              <div className="mb-5">
              <p className=" text-primary">PARENT'S LINK</p>
                <p className="h6">Link : <a target="_blank" href="/parentlogin">Parent Login</a></p>
                <p className="h6">Username : adamsfamily@gmail.com</p>
                <p className="h6">Password : wed12345</p>
              </div>


              <hr></hr>
              <div className="mb-5">
              <p className=" text-primary">TEACHER'S LINK</p>
                <p className="h6">Link : <a target="_blank" href="/teacherlogin">Teacher Login</a></p>
                <p className="h6">Username : sarah@dbs</p>
                <p className="h6">Password : sara123</p>
              </div>


              <hr></hr>
             
            </div>
          </div>
        </div>
        {/* Right Side End */}
      </div>
    </div>

    <ToastContainer />
  </>
  )
}

export default Cdxindex