import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Theader from "./Theader";
import Tfooter from "./Tfooter";
import { ClipLoader } from "react-spinners"; // Import the spinner

function Tassessment() {

    const [username, setUsername] = useState("");
    const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [teacherData, setTeacherData] = useState(null);
    const [schoolData, setSchoolData] = useState(null);
    const [loading, setLoading] = useState(true); 

    useEffect(() => {
        const storedUsername = sessionStorage.getItem("tusername");
        const storedSchoolid = sessionStorage.getItem("schoolid");
        const storedTeacherid = sessionStorage.getItem("tuid");

        if (storedUsername) {
           
              setUsername(storedUsername);
              fetchSchoolData(storedSchoolid);
              fetchTeacherData(storedTeacherid);
          } else {
            navigate("/teacherlogin");
          }
          //}
        }, [navigate]);

        const fetchSchoolData = async (id) => {
            setLoading(true);
            try {
                const res = await axios.get(apiUrl + "/schoolinfobyteacherid", { params: { id } });
                setSchoolData(res.data);
            } catch (err) {
                console.error("Error fetching school data:", err);
            } finally {
                setLoading(false);
            }
        };

        const fetchTeacherData = async (id) => {
            setLoading(true);
            try {
                const res = await axios.get(apiUrl + "/teacherinfobyteacherid", { params: { id } });
                setTeacherData(res.data);
        
            } catch (err) {
                console.error("Error fetching teacher data:", err);
            } finally {
                setLoading(false);
            }
        };
        

  return (
           <>
      <Helmet>
        <title>CODEX | Teacher | Assessment</title>
        <meta name="description" content="Teacher Courses Page." />
        <meta name="keywords" content="codex, school, react" />
      </Helmet>

      <Theader />

      <main>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-title-container">
                <h1 className="mb-0 pb-0 display-4" id="title">
                Assessment
                </h1>
                <div>
                  {loading ? (
                    <ClipLoader size={50} color={"#123abc"} loading={loading} /> // Show loading spinner
                  ) : (
                    <nav
                      className="breadcrumb-container d-inline-block"
                      aria-label="breadcrumb"
                    >
                      <ul className="breadcrumb pt-0">
                        <li className="breadcrumb-item">
                          <a href="#">Home</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="#">Courses</a>
                        </li>
                      </ul>

                      {teacherData ? (
                        <h3 className="mb-0 pb-0 display-6" id="title">
                          Welcome {teacherData.name}!
                        </h3>
                      ) : (
                        <h3 className="mb-0 pb-0 display-6" id="title">
                          {" "}
                          Loading school data...
                        </h3>
                      )}
                    </nav>
                  )}
                </div>
              </div>
            </div>
          </div>
          
    
    


   {/* BODY CONTENT START */}
    <section className="scroll-section" id="product">
      <h2 className="small-title">Assessment</h2>
      <section className="scroll-section" id="aligning">
        {/* <p>Your ward is not eligible for the certificate. Please complete the course!</p> */}
        <div className="card">
          <div className="card-body">
           {/*  <img
              src="img/general/cert.jpg"
              className="rounded mx-auto mb-1 d-block sw-50"
              alt="card image"
            />*/}
          </div>{" "}
          {/* <button
            className="btn btn-icon mx-auto btn-icon-end btn-primary mb-1"
            style={{ width: "10% !important" }}
            type="button"
          >
            <span>Download</span>
            <i data-acorn-icon="download" />
          </button>  */}
            <div style={{ textAlign: 'center' }}>This data will be fetched after integrating students module!</div>
            <div><br/><br/><br/></div>
        </div>
      </section>
    </section>
    {/* BODY CONTENT END */}




        </div>
      </main>

      <Tfooter />
      {/* </div> */}
    </>
  )
}

export default Tassessment