import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "react-toastify/dist/ReactToastify.css";
import Shheader from "./Shheader";
import Shfooter from "./Shfooter";


// const Shviewteachers = () => {
function Shviewteachers() {



  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [schoolData, setSchoolData] = useState(null);
  const [teachers, setTeachers] = useState([]);
  const [values, setValues] = useState({
    schoolid: "",
    username: "",
    password: "",
    name: "",
    email: "",
    phonenumber: "",
    grade: "",
    division: "",
  });

  useEffect(() => {
    fetchTeachers();
    const storedUsername = sessionStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
      fetchSchoolData(storedUsername);
    } else {
      navigate("/schoollogin");
    }
  }, []);

  const fetchSchoolData = async (username) => {
  await  axios
      .get(apiUrl+"/schoolinfo", { params: { username } })
      .then((res) => {
        setSchoolData(res.data);
        setValues((prevValues) => ({
          ...prevValues,
          schoolid: res.data.id,
        }));
      })
      .catch((err) => {
        console.error("Error fetching school data:", err);
      });
  };

  const fetchTeachers = async () => {

    const uu = await sessionStorage.getItem("uid")
    
   await axios
      .get(apiUrl+"/teachersbyschool", { params: { schoolid : uu } })
      .then((res) => {
        setTeachers(res.data);
      })
      .catch((err) => {
        console.error("Error fetching teacher data:", err);
      });
  };

  return (
    <>
      <Helmet>
        <title>CODEX | School | View Teachers</title>
        <meta name="description" content="View Teacher" />
        <meta name="keywords" content="codex, school, react" />
        {/* <script src="js/plugins/datatable.editableboxed.js" async></script> */}
      </Helmet>

      <Shheader />

      <main>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-title-container">
                {/* <h1 class="mb-0 pb-0 display-4" id="title">Teachers</h1> */}
                <nav
                  className="breadcrumb-container d-inline-block"
                  aria-label="breadcrumb"
                >
                  {/* <ul class="breadcrumb pt-0">
              <li class="breadcrumb-item"><a href="#">Home</a></li>
              <li class="breadcrumb-item"><a href="#">Teachers</a></li>
            </ul> */}
                  {schoolData ? (
                    <h3 className="mb-0 pb-0 display-6" id="title">
                      Welcome {schoolData.schoolname}!
                    </h3>
                  ) : (
                    <h3 className="mb-0 pb-0 display-6" id="title">
                      {"Loading school data..."}
                    </h3>
                  )}
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {/* Title and Top Buttons Start */}
              <div className="page-title-container">
                <div className="row">
                  {/* Title Start */}
                  <div className="col-12 col-md-7">
                    <h1 className="mb-0 pb-0 display-4" id="title">
                      Teachers
                    </h1>
                    <nav
                      className="breadcrumb-container d-inline-block"
                      aria-label="breadcrumb"
                    >
                      <ul className="breadcrumb pt-0">
                        <li className="breadcrumb-item">
                          <a href="#">Home</a>
                        </li>
                        <li className="breadcrumb-item">
                          <a href="#">Teachers</a>
                        </li>
                      </ul>
                    </nav>
                  </div>
                  {/* Title End */}
                  {/* Top Buttons Start */}
                  <div className="col-12 col-md-5 d-flex align-items-start justify-content-end">
                    {/* Add New Button Start */}
                    <a
                      href="/schooladdteacher"
                      className="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto"
                    >
                      <i data-acorn-icon="plus" />
                      <span>Add New.</span>
                    </a>
                    {/* <button type="button" class="btn btn-outline-primary btn-icon btn-icon-start w-100 w-md-auto add-datatable">
                
              </button> */}
                    {/* Add New Button End */}
                    {/* Check Button Start */}
                    {/* <div className="btn-group ms-1 check-all-container">
                      <div
                        className="btn btn-outline-primary btn-custom-control p-0 ps-3 pe-2"
                        id="datatableCheckAllButton"
                      >
                        <span className="form-check float-end">
                          <input
                            type="checkbox"
                            className="form-check-input"
                            id="datatableCheckAll"
                          />
                        </span>
                      </div>
                      <button
                        type="button"
                        className="btn btn-outline-primary dropdown-toggle dropdown-toggle-split"
                        data-bs-offset="0,3"
                        data-bs-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        data-submenu=""
                      />
                      <div className="dropdown-menu dropdown-menu-end">
                        <div className="dropdown dropstart dropdown-submenu">
                          <button
                            className="dropdown-item dropdown-toggle tag-datatable caret-absolute disabled"
                            type="button"
                          >
                            Active
                          </button>
                          <div className="dropdown-menu">
                            <button
                              className="dropdown-item tag-done"
                              type="button"
                            >
                              Done
                            </button>
                            <button
                              className="dropdown-item tag-new"
                              type="button"
                            >
                              New
                            </button>
                            <button
                              className="dropdown-item tag-sale"
                              type="button"
                            >
                              Sale
                            </button>
                          </div>
                        </div>
                        <div className="dropdown-divider" />
                        <button
                          className="dropdown-item disabled delete-datatable"
                          type="button"
                        >
                          Delete
                        </button>
                      </div>
                    </div> */}
                    {/* Check Button End */}
                  </div>
                  {/* Top Buttons End */}
                </div>
              </div>
              {/* Title and Top Buttons End */}
              {/* Content Start */}
            
              {/* Content End */}
              










  <section className="scroll-section" id="stripe">
  <div className="card mb-5">
    <div className="card-body">
      {/* Stripe Controls Start */}
      <div className="row">
        <div className="col-12 col-sm-5 col-lg-3 col-xxl-2 mb-1">
          <div className="d-inline-block float-md-start me-1 mb-1 search-input-container w-100 border border-separator bg-foreground search-sm">
            <input
              className="form-control form-control-sm datatable-search"
              placeholder="Search"
              data-datatable="#datatableStripe"
            />
            <span className="search-magnifier-icon">
              <i data-acorn-icon="search" />
            </span>
            <span className="search-delete-icon d-none">
              <i data-acorn-icon="close" />
            </span>
          </div>
        </div>
        <div className="col-12 col-sm-7 col-lg-9 col-xxl-10 text-end mb-1">
          <div className="d-inline-block">
            <button
              className="btn btn-icon btn-icon-only btn-outline-muted btn-sm datatable-print"
              type="button"
              data-datatable="#datatableStripe"
            >
              <i data-acorn-icon="print" />
            </button>
            <div
              className="d-inline-block datatable-export"
              data-datatable="#datatableStripe"
            >
              <button
                className="btn btn-icon btn-icon-only btn-outline-muted btn-sm dropdown"
                data-bs-toggle="dropdown"
                type="button"
                data-bs-offset="0,3"
              >
                <i data-acorn-icon="download" />
              </button>
              <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                <button className="dropdown-item export-copy" type="button">
                  Copy
                </button>
                <button className="dropdown-item export-excel" type="button">
                  Excel
                </button>
                <button className="dropdown-item export-cvs" type="button">
                  Cvs
                </button>
              </div>
            </div>
            <div
              className="dropdown-as-select d-inline-block datatable-length"
              data-datatable="#datatableStripe"
            >
              <button
                className="btn btn-outline-muted btn-sm dropdown-toggle"
                type="button"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                data-bs-offset="0,3"
              >
                10 Items
              </button>
              <div className="dropdown-menu dropdown-menu-sm dropdown-menu-end">
                <a className="dropdown-item" href="#">
                  5 Items
                </a>
                <a className="dropdown-item active" href="#">
                  10 Items
                </a>
                <a className="dropdown-item" href="#">
                  20 Items
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Stripe Controls End */}
      {/* Stripe Table Start */}
      <table
        className="data-table data-table-pagination data-table-standard responsive nowrap stripe"
        id="datatableStripe"
        data-order='[[ 0, "desc" ]]'
      >
         <thead><tr>
                        <th className="text-muted text-small text-uppercase">Name</th>
                        <th className="text-muted text-small text-uppercase">Grade</th>
                        <th className="text-muted text-small text-uppercase">Division</th>
                        <th className="text-muted text-small text-uppercase">User name</th>
                        <th className="text-muted text-small text-uppercase">Password</th>
                        <th className="text-muted text-small text-uppercase">Status</th>
                        {/* <th className="text-muted text-small text-uppercase">Action</th> */}
                    </tr></thead>
                    <tbody>
                      {teachers.map((teacher, index) => (
                        <tr key={index}>
                          <td>{teacher.name}</td>
                          <td>{teacher.grade}</td>
                          <td>{teacher.division}</td>
                          <td>{teacher.username}</td>
                          <td>{teacher.password}</td>
                          <td>{teacher.status === 1 ? "Active" : "Inactive"}</td>
                          {/* <td> <Link className='btn mx-2 btn-blue' to={`/schooleditteacher/${encryptId(teacher.id)}`}>Edit</Link></td>                           */}
                          {/* <td> <Link className='btn mx-2 btn-blue' to={`/schooleditteacher/4`}>Edit</Link></td>                           */}
                        </tr>
                      ))}
                    </tbody>

      </table>
      {/* Stripe Table End */}
    </div>
  </div>
</section>















            </div>
          </div>
        </div>
      </main>

      <Shfooter />
    </>
  );
}

export default Shviewteachers;
