import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Shheader from "./Shheader";
import Shfooter from "./Shfooter";
import { format } from "date-fns";
import useScript from "../hooks/useScript";
import CryptoJS from "crypto-js";
import Shquicksidemenu from "./Shquicksidemenu";

function Shpreferences() {

  const [username, setUsername] = useState('');
  const [schoolData, setSchoolData] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;


  const [settings, setSettings] = useState({
    securityWarnings: false,
    newFeatureNotifications: false,
    generalNotifications: false,
    newsletter: false,
});



useEffect(() => {
  const storedUsername = sessionStorage.getItem("username");
  setUsername(storedUsername);
  fetchSchoolData(storedUsername);
    const fetchSettings = async () => {
        try {
            const response = await axios.get(apiUrl+'/getNotificationSettings', {
                params: { id: sessionStorage.getItem("uid") }
            });
            const data = response.data;
            setSettings({
                securityWarnings: data['security-warnings'] === 1,
                newFeatureNotifications: data['new-feature-notifications'] === 1,
                generalNotifications: data['general-notifications'] === 1,
                newsletter: data['newsletter'] === 1,
            });
        } catch (error) {
            console.error('Error fetching settings:', error);
        }
    };

    fetchSettings();
}, []);


const fetchSchoolData = (username) => {
  axios.get(apiUrl+'/schoolinfo', {params:{username}})
    .then((res) => {
      setSchoolData(res.data);
    
    })
    .catch((err) => {
      console.error('Error fetching school data:', err);
    });
};


const handleChange = (e) => {
    const { id, checked } = e.target;
    setSettings((prevSettings) => ({
        ...prevSettings,
        [id]: checked,
    }));
};

const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        await axios.post(apiUrl+'/updateNotificationSettings', {
            id: sessionStorage.getItem("uid"),
            'security-warnings': settings.securityWarnings ? 1 : 0,
            'new-feature-notifications': settings.newFeatureNotifications ? 1 : 0,
            'general-notifications': settings.generalNotifications ? 1 : 0,
            'newsletter': settings.newsletter ? 1 : 0,
        });
       // alert('Settings updated successfully');
        toast.success("Settings updated successfully");
        window.location.reload();
    } catch (error) {
        console.error('Error updating settings:', error);
       // alert('Error updating settings');
       toast.success("Error updating settings");
    }
};

  return (

    <>
      <Helmet>
        <title>CODEX | School | Competitions</title>
        <meta name="description" content="View Competitions" />
        <meta name="keywords" content="codex, school, react" />
        {/* <script src="js/plugins/datatable.editableboxed.js" async></script> */}
      </Helmet>

      <Shheader />

      <main>
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="page-title-container">
          {/* <h1 class="mb-0 pb-0 display-4" id="title">Teachers</h1> */}
          <nav
            className="breadcrumb-container d-inline-block"
            aria-label="breadcrumb"
          >
            {schoolData ? (
                    <h3 className="mb-0 pb-0 display-6" id="title">
                      Welcome {schoolData.schoolname}!
                    </h3>
                  ) : (
                    <h3 className="mb-0 pb-0 display-6" id="title">
                      {"Loading school data..."}
                    </h3>
                  )}
          </nav>
        </div>
      </div>
    </div>
    <div className="row">
      <div className="col">
        {/* Title Start */}
        {/* <section class="scroll-section" id="title">
          <div class="page-title-container">
            <h1 class="mb-0 pb-0 display-4">Profile</h1>
            <nav class="breadcrumb-container d-inline-block" aria-label="breadcrumb">
              <ul class="breadcrumb pt-0">
                <li class="breadcrumb-item"><a href="#">Home</a></li>
                <li class="breadcrumb-item"><a href="#">Request Seats</a></li>
              </ul>
            </nav>
          </div>
        </section> */}
        {/* Title End */}
        {/* Content Start */}
        <div className="row">
          {/* Left Side Start */}
          <div className="col-12 col-xl-4 col-xxl-3">
            {/* Biography Start */}
            <h2 className="small-title">Profile</h2>
            
            <div className="card mb-5">
                    <div className="card-body">
                      <div className="d-flex align-items-center flex-column mb-4">
                        <div className="d-flex align-items-center flex-column">
                          <div className="sw-13 position-relative mb-3">
                            <div className="h5 mb-0">
                              <strong>Quick Menu</strong>
                            </div>
                          </div>
                        </div>
                      </div>
                      <Shquicksidemenu />
                    </div>
                  </div>

            
            {/* Biography End */}
          </div>
          {/* Left Side End */}
          {/* Right Side Start */}
          <div className="col-12 col-xl-8 col-xxl-9 mb-5 tab-content">
            <h2 className="small-title">Preferences</h2>
            <div className="card mb-5">
              <div className="card-body">
                <form className="mb-4" onSubmit={handleSubmit}>
                  <div className="mb-3 filled custom-control-container">
                    <i data-acorn-icon="shield" />
                    <div className="form-check form-switch">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="securityWarnings"
                        checked={settings.securityWarnings}
                        onChange={handleChange}
                        //defaultChecked=""
                      />
                      <label
                        className="form-check-label"
                        htmlFor="securityWarnings"
                      >
                        Security Warnings
                      </label>
                    </div>
                  </div>
                  <div className="mb-3 filled custom-control-container">
                    <i data-acorn-icon="server" />
                    <div className="form-check form-switch">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="newFeatureNotifications"
                            checked={settings.newFeatureNotifications}
                            onChange={handleChange}
                      />
                      <label className="form-check-label" htmlFor="quotaCheck">
                        New feature Notifications
                      </label>
                    </div>
                  </div>
                  <div className="mb-3 filled custom-control-container">
                    <i data-acorn-icon="bell" />
                    <div className="form-check form-switch">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="generalNotifications"
                        checked={settings.generalNotifications}
                        onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="generalCheck"
                      >
                        General Notifications
                      </label>
                    </div>
                  </div>
                  <div className="mb-3 filled custom-control-container">
                    <i data-acorn-icon="news" />
                    <div className="form-check form-switch">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="newsletter"
                            checked={settings.newsletter}
                            onChange={handleChange}
                      />
                      <label
                        className="form-check-label"
                        htmlFor="newsletterCheck"
                      >
                        Newsletter
                      </label>
                    </div>
                  </div>

                  <button type="submit" className="btn btn-primary">Update</button>

                </form>
              </div>
            </div>
          </div>
          {/* Right Side End */}
        </div>
        {/* Content End */}
      </div>
    </div>
  </div>
</main>


    <Shfooter />
      <ToastContainer />
    </>
  )
}

export default Shpreferences