import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Shheader from "./Shheader";
import Shfooter from "./Shfooter";

const SeatCalculator = () => {
  const [seats, setSeats] = useState('');
  const [total, setTotal] = useState(0);

  const handleSeatsChange = (e) => {
    const seatCount = e.target.value;
    setSeats(seatCount);
    setTotal(seatCount * 250); // Assuming 10 AED per seat
  };

  return (
    <div>
      <div className="mb-3 filled col-md-3">
        <i data-acorn-icon="office" />
        <input
          className="form-control"
          type="number"
          name="rseats"
          placeholder="How many seats you want?"
          value={seats}
          onChange={handleSeatsChange}
          required 
        />
      </div>
      <div className="mb-3 filled col-md-3">
        <i data-acorn-icon="office" />
        <input
          className="form-control"
          type="text"
          name="total"
          placeholder="Total : AED."
          value={`Total: AED ${total}`}
          readOnly
        />
      </div>
    </div>
  );
};

export const Shrequestseats = () => {
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const [schoolData, setSchoolData] = useState(null);
  const apiUrl = process.env.REACT_APP_API_URL;
  const [values, setValues] = useState({
    schoolid: "",
    schoolseats: "",
  });

  useEffect(() => {
    const storedUsername = sessionStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
      fetchSchoolData(storedUsername);
    } else {
      navigate("/schoollogin");
    }
  }, []);

  const fetchSchoolData = (username) => {
    axios
      .get(apiUrl+"/schoolinfo", { params: { username } })
      .then((res) => {
        setSchoolData(res.data);
        setValues((prevValues) => ({
          ...prevValues,
          schoolid: res.data.id,
        }));
      })
      .catch((err) => {
        console.error("Error fetching school data:", err);
      });
  };


  const handleClick = () => {
    toast.error('Please connect Stripe payment gateway with your Account');
  };


  return (
    <>
      <Helmet>
        <title>CODEX | School | Request Seats</title>
        <meta name="description" content="Request Seats" />
        <meta name="keywords" content="codex, school, react" />
      </Helmet>

      <Shheader />

      <main>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-title-container">
                <nav
                  className="breadcrumb-container d-inline-block"
                  aria-label="breadcrumb"
                >
                  {schoolData ? (
                    <h3 className="mb-0 pb-0 display-6" id="title">
                      Welcome {schoolData.schoolname}!
                    </h3>
                  ) : (
                    <h3 className="mb-0 pb-0 display-6" id="title">
                      {"Loading school data..."}
                    </h3>
                  )}
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <section className="scroll-section" id="title">
                <div className="page-title-container">
                  <h1 className="mb-0 pb-0 display-4">Request Seats</h1>
                  <nav
                    className="breadcrumb-container d-inline-block"
                    aria-label="breadcrumb"
                  >
                    <ul className="breadcrumb pt-0">
                      <li className="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#">Request Seats</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </section>
              <div>
                <div className="card mb-5">
                  <div className="card-body">
                    <p className="mb-0">Request additional Codex Seats / Licenses.</p>
                  </div>
                </div>
                <section className="scroll-section" id="login">
                  <h2 className="small-title">Request for additional seats</h2>
                  <form className="card mb-5 tooltip-end-top">

                  <div className="card-body">
                 <p className="text-alternate mb-4">
                   {/* Request. */}
                 </p>
                 <div className="mb-3 filled">
                   {/* <i data-acorn-icon="login" /> */}
                   <div><strong>Seats assigned:</strong></div>
                   {schoolData ? ( <p> {schoolData.seats} seats assigned.</p> ) : ( <p> Loading seats assigned.</p> )} 
                 </div>
                 <div className="mb-3 filled">
                   {/* <i data-acorn-icon="logout" /> */}
                   <div><strong>Seats Utilized:</strong></div>
                   {schoolData ? ( <p> {schoolData.students} seats utilized.</p> ) : ( <p> Loading seats utilized.</p> )} 
                </div>

                    <SeatCalculator />
                 
                    {/* Add other form fields and buttons here */}
                    <div><div>
                          <button class="btn btn-icon btn-icon-end btn-primary" type="button"  onClick={handleClick}>
                            <span>Request</span>
                            <i data-acorn-icon="chevron-right"></i>
                          </button>
                          </div></div>
                          </div>
                  </form>
                </section>
              </div>
            </div>
          </div>
        </div>
      </main>

      <Shfooter />
      <ToastContainer />
    </>
  );
};

export default Shrequestseats;
