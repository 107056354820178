import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pheader from "../parent/Pheader";
import Pfooter from "../parent/Pfooter";
import { format } from "date-fns";

function Pcompetitions() {

  const apiUrl = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [student, setStudent] = useState(null);
    const [schoolData, setSchoolData] = useState(null);
    const [competitions, setcompetitions] = useState([]);
    const [pastcompetitions, setpastcompetitions] = useState([]);
    const [values, setValues] = useState({
        schoolid: "",
        username: "",
      });

    useEffect(() => {

        const puid = sessionStorage.getItem("puid");
        const storedUsername = sessionStorage.getItem("pusername");

        if (storedUsername) {
          getstudentinfo();
          fetchSchoolData();
          fetchcompetitions();
          fetchpastcompetitions();
        }
        else {
          navigate("/parentlogin");
        }
 
          
       

    }, []);

    const getstudentinfo = async () => {

        const puid = sessionStorage.getItem("puid");
       await axios.get(apiUrl+'/parentinfo', { params: {puid} })
       .then(response => {
         setStudent(response.data);
         sessionStorage.setItem("grade", response.data.grade);
         sessionStorage.setItem("division", response.data.division);
       })
       .catch(error => {
         console.error('Error fetching data:', error);
       });
 
     };

     const fetchSchoolData = async (username) => {
        await axios
          .get(apiUrl+"/schoolinfo", { params: { username } })
          .then((res) => {
            setSchoolData(res.data);
            sessionStorage.setItem("uid", res.data.id);
            setValues((prevValues) => ({
              ...prevValues,
              schoolid: res.data.id,
            }));
          })
          .catch((err) => {
            console.error("Error fetching school data:", err);
          });
      };


      const fetchcompetitions = async () => {
      await axios
          .get(apiUrl+"/competitions")
          .then((res) => {
            setcompetitions(res.data);
          })
          .catch((err) => {
            console.error("Error fetching data:", err);
          });
      };


      const fetchpastcompetitions = async () => {
       await axios
          .get(apiUrl+"/pastcompetitions")
          .then((res) => {
            setpastcompetitions(res.data);
          })
          .catch((err) => {
            console.error("Error fetching data:", err);
          });
      };
     

  return (

    <>
    <Helmet>
      <title>CODEX | Parent | Competitions</title>
      <meta name="description" content="Parent Competition Page." />
      <meta name="keywords" content="codex, parent, react" />
    </Helmet>

    <Pheader />

  
    <main>
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="page-title-container">
          <h1 class="mb-0 pb-0 display-4" id="title">Competitions</h1><br/>
          <nav
            className="breadcrumb-container d-inline-block"
            aria-label="breadcrumb"
          >
           {student ? (
                    <h3 className="mb-0 pb-0 display-6" id="title">
                        Welcome parent of {student ? student.studentname : <p>No data available</p> }
                    </h3>
                  ) : (
                    <h3 className="mb-0 pb-0 display-6" id="title">
                      {"Loading school data..."}
                    </h3>
                  )}
          </nav>
        </div>
      </div>
    </div>
    {/* competitions */}
    <section className="scroll-section" id="product">
      <h2 className="small-title">Active Competitions</h2>
      <div className="card">
        <div className="card-body">



        {competitions.map((comp) => (
        <div key={comp.id}> 
          <div className="row">
            <div className="col-12 col-xl-6">
              <div className="glide glide-gallery" id="glideProductGallery">
                {/* Large Images Start */}
                <div className="glide glide-large">
                  <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides gallery-glide-custom">
                      <div className="glide__slide p-0">
                        <a href="#">
                          <img
                            alt="detail"
                            src={`uploads/${comp.featuredimage}`}
                            className="responsive border-0 rounded-md img-fluid mb-3 sh-24 sh-md-35 sh-xl-50 w-100"
                          />
                        </a>
                      </div>
                    </ul>
                  </div>
                </div>
                {/* Large Images End */}
              </div>
            </div>
            <div className="col-12 col-xl-6">
              <a className="mb-1 d-inline-block text-small" href="#">
                Competitions
              </a>
              <h4 className="mb-4">{comp.title}</h4>
              <div>Event Date : {format(new Date(comp.eventdate), 'MMMM do, yyyy')}</div>
              <div>
                <div className="text-muted d-inline-block text-small align-text-top">
                  ({comp.appliedcount} Enrolled)
                </div>
              </div>
              <p className="mt-2 mb-4">
              {comp.description}
              </p>
              <div className="row g-3 mb-4">
                <div className="mb-3 col-12 col-sm-auto me-1">
                  <label className="fw-bold form-label">Event Type</label>
                  <div className="pt-1">
                    <div className="">
                      {/* <input
                        type="radio"
                        className="form-check-input"
                        name="inlineRadio1"
                        id="inlineRadio"
                        defaultChecked=""
                      /> */}
                      <label className="form-check-label">
                      <p><i data-acorn-icon="tag"></i> {comp.eventtype}</p>
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div>
                <div style={{ color: "green" }}>
                  <strong> Applied : {comp.appliedcount}</strong>
                </div>
              </div>
            </div>
          </div>
          </div>
        ))}





        </div>
      </div>
    </section>
    <div>
      <br />
    </div>



    <section className="scroll-section" id="product">
  <h2 className="small-title">Past Competitions</h2>

  {pastcompetitions.map((pcomp) => (
    <div key={pcomp.id}>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-xl-6">
              <div className="glide glide-gallery" id="glideProductGallery">
                {/* Large Images Start */}
                <div className="glide glide-large">
                  <div className="glide__track" data-glide-el="track">
                    <ul className="glide__slides gallery-glide-custom">
                      <div className="glide__slide p-0">
                        <a href="#">
                          <img
                            alt="detail"
                            src={`img/general/${pcomp.featuredimage}`}
                            className="responsive border-0 rounded-md img-fluid mb-3 sh-24 sh-md-35 sh-xl-50 w-100"
                          />
                        </a>
                      </div>
                    </ul>
                  </div>
                </div>
                {/* Large Images End */}
              </div>
            </div>
            <div className="col-12 col-xl-6">
              <a className="mb-1 d-inline-block text-small" href="#">
                Competitions
              </a>
              <h4 className="mb-4">{pcomp.title}</h4>
              <div>Event Ended: {format(new Date(pcomp.eventdate), 'MMMM do, yyyy')}</div>
              <div>
                <div className="text-muted d-inline-block text-small align-text-top">
                  ({pcomp.appliedcount} Enrolled)
                </div>
              </div>
              <p className="mt-2 mb-4">
                {pcomp.description}
              </p>
              <div className="mb-3 col-12 col-sm-auto me-1">
                <div className="pt-1">
                  <div>
                    <strong>
                      <a href={`uploads/${pcomp.result}`} target="_blank">
                        View Results
                      </a>
                    </strong>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  ))}
</section>



  </div>
  {/* competitions */}
</main>


    <Pfooter />
    <div><br/></div>
    <ToastContainer />
</>
  )
}

export default Pcompetitions