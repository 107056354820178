import React from 'react'

function Tfooter() {
  return (
    <>
    {/* <footer> */}
   <div className="footer-content">
     <div className="container">
       <div className="row">
         <div className="col-12 col-sm-6">
           <p className="mb-0 text-muted text-medium">
             Copyright © 2024-2029 Codex Books Online. All rights reserved.
           </p>
         </div>
         <div className="col-sm-6 d-none d-sm-block">
           <ul className="breadcrumb pt-0 pe-0 mb-0 float-end">
             <li className="breadcrumb-item mb-0 text-medium">
               <a href="#" target="_blank" className="btn-link">
                 Privacy Policy
               </a>
             </li>
             <li className="breadcrumb-item mb-0 text-medium">
               <a href="#" target="_blank" className="btn-link">
                 Terms &amp; Conditions
               </a>
             </li>
             <li className="breadcrumb-item mb-0 text-medium">
               <a href="#" target="_blank" className="btn-link">
                 Cookies Policy
               </a>
             </li>
           </ul>
         </div>
       </div>
     </div>
   </div>
 {/* </footer> */}
 <div>&nbsp;</div>
 
    
    </>
  )
}

export default Tfooter