import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
//import config from '../config';

function Tester() {

    const [message, setMessage] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;


    useEffect(() => {
      toast.success(apiUrl);
        axios.get(apiUrl+'/check-db')
          .then(response => {
            if (response.data.success) {
              toast.success(response.data.message);
            } else {
              toast.error(response.data.message);
            }
            setMessage(response.data.message);
          })
          .catch(error => {
            toast.error('Error: ' + error.message);
            setMessage('Error: ' + error.message);
          });
      }, []);


    // function handleSubmit(e) {
    //     e.preventDefault();
    //     toast.success("Get DB Status");
    //     axios.get('/dbstatus', { params: {puid} })
    //     .then(response => {
    //         setDbtest(response.data);  
    //         //response.data.grade;
    //         toast.success('connected');
    //     })
    //     .catch(error => {
    //       toast.error('Error fetching data:', error);
    //     });

      
    //   }





  return (

    <>
    <Helmet>
      <title>CODEX | MAIN PAGE</title>
      <meta name="description" content="Main Page." />
      <meta name="keywords" content="codex, react" />
    </Helmet>
    <div style={{ padding: '100px' }}>
    <div>Tester</div>

    <h1>Database Connection Status</h1>
    <p>{message}</p>
    {/* <form onSubmit={handleSubmit} >
    <button type="submit" className="btn btn-lg btn-primary"> Test DB </button>
    </form> */}

    </div>
    <ToastContainer />
    </>
    
  )
}

export default Tester