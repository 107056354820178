import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pheader from "../parent/Pheader";
import Pfooter from "../parent/Pfooter";
import { format } from "date-fns";

function Passignment() {

    const navigate = useNavigate();
    const [student, setStudent] = useState(null);
    const [assignments, setAssignments] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {

        const puid = sessionStorage.getItem("puid");
        const storedUsername = sessionStorage.getItem("pusername");

        if (storedUsername) {
          getstudentinfo();
          getassignments();
        }
        else {
          navigate("/parentlogin");
        }
        
       
        
      //  toast.success(sessionStorage.getItem("puid")+"-"+sessionStorage.getItem("grade")+"-"+sessionStorage.getItem("division"))

    }, []);

    const getassignments = async () => {

        const schoolidd = sessionStorage.getItem("pschoolid");
        const gradee = sessionStorage.getItem("grade");
        const divisionn = sessionStorage.getItem("division");

      await axios.get(apiUrl+'/parentgetassignments', { params: {schoolidd,gradee,divisionn} })
        .then(response => {
            setAssignments(response.data);         
        })
        .catch(error => {
          console.error('Error fetching data:', error);
        });
 
        
    };

    const getstudentinfo = async () => {

        const puid = sessionStorage.getItem("puid");
       await axios.get(apiUrl+'/parentinfo', { params: {puid} })
       .then(response => {
         setStudent(response.data);
         sessionStorage.setItem("grade", response.data.grade);
         sessionStorage.setItem("division", response.data.division);
       })
       .catch(error => {
         console.error('Error fetching data:', error);
       });
 
     };


  return (
    <>
    <Helmet>
      <title>CODEX | Parent | Assignments</title>
      <meta name="description" content="Parent Assignments Page." />
      <meta name="keywords" content="codex, parent, react" />
    </Helmet>

    <Pheader />

    <main>
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="page-title-container">
          <h1 className="mb-0 pb-0 display-4" id="title">
            Assignment
          </h1>
          <nav
            className="breadcrumb-container d-inline-block"
            aria-label="breadcrumb"
          >
            <ul className="breadcrumb pt-0">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Assignment</a>
              </li>
            </ul>
            <h3 className="mb-0 pb-0 display-6" id="title">
              Welcome parent of {student ? student.studentname : <p>...</p> }!
            </h3>
          </nav>
        </div>
      </div>
    </div>
    {/* BODY CONTENT START */}
    <div>
    {assignments.map((assg) => (
        <div key={assg.id}> 

    <section className="scroll-section" id="product">
      <h2 className="small-title">Assignment</h2>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-12 col-xl-6">
              <div className="glide glide-gallery" id="glideProductGallery">
                {/* Large Images Start */}
                <div className="glide glide-large">
                  <div className="glide__track" data-glide-el="track">
                    <div className="glide__slides gallery-glide-custom">
                      <div className="glide__slide p-0">
                        <a href="#">
                          <img
                            alt="detail"
                            // src="img/general/assignment.jpg"
                            src={`uploads/${assg.featuredimage}`}
                            className="responsive border-0 rounded-md img-fluid mb-3 sh-24 sh-md-35 sh-xl-50 w-100"
                          />
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Large Images End */}
              </div>
            </div>
            <div className="col-12 col-xl-6">
              <a className="mb-1 d-inline-block text-small" href="#">
                Assignment
              </a>
              <h4 className="mb-4">{assg.title}</h4>
              <div>Deadline :{format(new Date(assg.deadline), 'MMMM do, yyyy')}</div>
              <div>
                <div className="text-muted d-inline-block text-small align-text-top">
                  ({assg.submissioncount} Submitted)
                </div>
              </div>
              <p className="mt-2 mb-4">
              {assg.assignmentdetails}
              </p>
            
              <div>
                {/* <button
                  className="btn btn-icon btn-icon-start btn-primary mb-1"
                  type="button"
                >
                  <i data-acorn-icon="check" />
                  <span>Submit</span>
                </button> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    </div>
))}
</div>


    {/* BODY CONTENT END */}

  </div>
</main>


    <Pfooter />
    <div><br/></div>
    <ToastContainer />
</>
  )
}

export default Passignment