import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Pheader from "../parent/Pheader";
import Pfooter from "../parent/Pfooter";
import { format } from "date-fns";

function Pcertificate() {
    const navigate = useNavigate();
    const [student, setStudent] = useState(null);
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        
        const storedUsername = sessionStorage.getItem("pusername");

        if (storedUsername) {
          getstudentinfo();
        }
        else {
          navigate("/parentlogin");
        }
    }, []);


    const getstudentinfo = () => {

        const puid = sessionStorage.getItem("puid");
        axios.get(apiUrl+'/parentinfo', { params: {puid} })
       .then(response => {
         setStudent(response.data);        
       })
       .catch(error => {
         console.error('Error fetching data:', error);
       });
 
     };

  return (
    <>
    <Helmet>
      <title>CODEX | Parent | Certificates</title>
      <meta name="description" content="Parent Certificate Page." />
      <meta name="keywords" content="codex, parent, react" />
    </Helmet>

    <Pheader />

    <main>
  <div className="container">
    <div className="row">
      <div className="col-12">
        <div className="page-title-container">
          <h1 className="mb-0 pb-0 display-4" id="title">
            Certificate
          </h1>
          <nav
            className="breadcrumb-container d-inline-block"
            aria-label="breadcrumb"
          >
            <ul className="breadcrumb pt-0">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item">
                <a href="#">Certificate</a>
              </li>
            </ul>
            <h3 className="mb-0 pb-0 display-6" id="title">
              Welcome parent of {student ? student.studentname : <p>No data available</p> }!
            </h3>
          </nav>
        </div>
      </div>
    </div>
    {/* BODY CONTENT START */}
    <section className="scroll-section" id="product">
      <h2 className="small-title">Certificate</h2>
      <section className="scroll-section" id="aligning">
        <p>Your ward is not eligible for the certificate. Please complete the course!</p>
        <div className="card">
          <div className="card-body">
           {/*  <img
              src="img/general/cert.jpg"
              className="rounded mx-auto mb-1 d-block sw-50"
              alt="card image"
            />*/}
          </div>{" "}
          {/* <button
            className="btn btn-icon mx-auto btn-icon-end btn-primary mb-1"
            style={{ width: "10% !important" }}
            type="button"
          >
            <span>Download</span>
            <i data-acorn-icon="download" />
          </button>  */}
            <div style={{ textAlign: 'center' }}>Please complete the course to get the certificate!</div>
            <div><br/><br/><br/></div>
        </div>
      </section>
    </section>
    {/* BODY CONTENT END */}
  </div>
</main>


    <Pfooter />
    <div><br/></div>
    <ToastContainer />
</>

  )
}

export default Pcertificate