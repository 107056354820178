import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Shheader from "./Shheader";
import Shfooter from "./Shfooter";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import the styles

function Shnewticket() {
  const [username, setUsername] = useState("");
  const navigate = useNavigate();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [schoolData, setSchoolData] = useState(null);
  const [supportcategory, setsupportcategory] = useState([]);
  const [values, setValues] = useState({
    schoolid: "",
    userid: "",
  });
  const [formValues, setFormValues] = useState({
    schoolid: "",
    title: "",
    description: "",
    category: "",
    file: null,
  });
  const modules = {
    toolbar: [
      [{ header: "1" }, { header: "2" }, { font: [] }],
      [{ size: [] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [
        { list: "ordered" },
        { list: "bullet" },
        { indent: "-1" },
        { indent: "+1" },
      ],
      ["link", "image", "video"],
      ["clean"],
    ],
  };

  const formats = [
    "header",
    "font",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  useEffect(() => {
    fetchCategory();
    //fetchDivisions();
    const storedUsername = sessionStorage.getItem("username");
    if (storedUsername) {
      setUsername(storedUsername);
      fetchSchoolData(storedUsername);
    } else {
      navigate("/schoollogin");
    }
  }, []);

  const fetchSchoolData = (username) => {
    axios
      .get(apiUrl+"/schoolinfo", { params: { username } })
      .then((res) => {
        setSchoolData(res.data);
        setValues((prevValues) => ({
          ...prevValues,
          schoolid: res.data.id,
        }));
      })
      .catch((err) => {
        console.error("Error fetching school data:", err);
      });
  };

  const fetchCategory = () => {
    axios
      .get(apiUrl+"/supportcategory")
      .then((res) => {
        setsupportcategory(res.data);
      })
      .catch((err) => {
        console.error("Error fetching data:", err);
      });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const handleFileChange = (e) => {
    setFormValues({ ...formValues, file: e.target.files[0] });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const uu = sessionStorage.getItem("uid");
    formData.append("schoolid", uu);
    formData.append("title", formValues.title);
    formData.append("description", values);
    formData.append("category", formValues.category);
    formData.append("file", formValues.file);

    axios
      .post(apiUrl+"/createsupportticket", formData)
      .then((response) => {
        toast.success("Ticket submitted successfully. We will get back to you!");
        console.log("Ticket submitted successfully:", response.data);
      })
      .catch((error) => {
        toast.error("Something went wrong. Please try after some time!");
        console.error("Error submitting ticket:", error);
      });
  };

  return (
    <>
      <Helmet>
        <title>CODEX | School | New Support Ticket</title>
        <meta name="description" content="New Ticket" />
        <meta name="keywords" content="codex, school, react" />
      </Helmet>

      <Shheader />

      <main>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-title-container">
                {/* <h1 class="mb-0 pb-0 display-4" id="title">Teachers</h1> */}
                <nav
                  className="breadcrumb-container d-inline-block"
                  aria-label="breadcrumb"
                >
                  {schoolData ? (
                    <h3 className="mb-0 pb-0 display-6" id="title">
                      Welcome {schoolData.schoolname}!
                    </h3>
                  ) : (
                    <h3 className="mb-0 pb-0 display-6" id="title">
                      {"Loading school data..."}
                    </h3>
                  )}
                </nav>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              {/* Title Start */}
              <section className="scroll-section" id="title">
                <div className="page-title-container">
                  <h1 className="mb-0 pb-0 display-4">Raise a Ticket</h1>
                  <nav
                    className="breadcrumb-container d-inline-block"
                    aria-label="breadcrumb"
                  >
                    <ul className="breadcrumb pt-0">
                      <li className="breadcrumb-item">
                        <a href="#">Home</a>
                      </li>
                      <li className="breadcrumb-item">
                        <a href="#">Raise a Ticket</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </section>
              {/* Title End */}
              {/* Content Start */}
              <div>
                <form
                  className="card mb-5 tooltip-end-top"
                  noValidate=""
                  onSubmit={handleSubmit}
                >
                  <input
                    type="hidden"
                    name="schoolid"
                    value={values.schoolid}
                  />

                  <section className="scroll-section" id="login">
                    <p>
                      <br />
                    </p>
                    <h2 className="small-title">
                      &nbsp;&nbsp;Support Ticket Details
                    </h2>

                    <div className="card-body">
                      <p className="text-alternate mb-4">
                        Title/Subject of the ticket.
                      </p>
                      <div className="mb-3 filled">
                        <i data-acorn-icon="light-on"></i>
                        <input
                          className="form-control"
                          placeholder="Ticket Title"
                          name="title"
                          value={formValues.title}
                          onChange={handleInputChange}
                          required
                        />
                      </div>

                      <p>
                        <br />
                      </p>

                      <p className="text-alternate mb-4">
                        Mention your problem / concern as detail as you can.
                      </p>
                      <div className="mb-3 filled">
                        <i data-acorn-icon="light-on"></i>
                        <ReactQuill
                          value={values}
                          placeholder="Keep typing here and the textarea will grow..."
                          height="500px !important"
                          rows={10}
                          onChange={setValues}
                          modules={modules}
                          formats={formats}
                        />
                      </div>

                      <p>
                        <br />
                      </p>

                      <div className="mb-3">
                        <p className="text-alternate mb-4">
                          <i data-acorn-icon="tag"></i> Category
                        </p>
                        {/* <label class="form-label"><i data-acorn-icon="air-balloon"></i> Choose Grade</label> */}
                        <select
                          className="form-control"
                          name="category"
                          value={formValues.category}
                          onChange={handleInputChange}
                          required
                        >
                          <option value="">Select Category</option>
                          {supportcategory.map((category) => (
                            <option key={category.id} value={category.id}>
                              {category.categoryname}
                            </option>
                          ))}
                        </select>
                      </div>

                      <p>
                        <br />
                      </p>

                      <p className="text-alternate mb-4">Supporting Document</p>
                      <div className="mb-3">
                        <input
                          type="file"
                          id="inputGroupFile01"
                          className="form-control"
                          name="file"
                          onChange={handleFileChange}
                        />
                      </div>

                      <button type="submit" className="btn btn-primary">
                        Submit Ticket
                      </button>
                      {/* </form> */}
                    </div>
                  </section>
                </form>
              </div>
              {/* Content End */}
            </div>
            {/* Scrollspy Start */}
            <div className="col-md-auto d-none d-lg-block" id="scrollSpyMenu">
              <ul className="nav flex-column">
                <li>
                  <a className="nav-link" href="#title">
                    <i data-acorn-icon="chevron-right" />
                    <span>Raise a Ticket</span>
                  </a>
                </li>
              </ul>
            </div>
            {/* Scrollspy End */}
          </div>
        </div>
      </main>

      <Shfooter />
      <ToastContainer />
    </>
  );
}

export default Shnewticket;
